import { createClient } from '@supabase/supabase-js';

import type { Database } from './types';

const SUPABASE_URL = 'https://oqjgaugmnpblvmyooyzr.supabase.co';

const SUPABASE_CLIENT_API_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9xamdhdWdtbnBibHZteW9veXpyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODE3NTIxMDAsImV4cCI6MTk5NzMyODEwMH0.P07JIwZpU8OOJwdfreNozTOjpx67MhSq2U6iUWuhWg0';

export const supabase = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_CLIENT_API_KEY,
);
