import type {
  PressableStateCallbackType,
  StyleProp,
  ViewStyle,
} from 'react-native';

import { Pressable as RNPressable } from 'react-native';

export const Pressable = RNPressable;

export type GetPressableStyle = (
  state: PressableStateCallbackType,
) => StyleProp<ViewStyle>;
