import type { IValidateTokenResponse } from '@putdotio/api-client';
import type PutioAPIClient from '@putdotio/api-client';
import type { UseQueryOptions } from '@tanstack/react-query';

import { useAPI } from '@putdotio/core';
import { useQuery } from '@tanstack/react-query';

import { TokenValidationError } from '../errors';

export type Options = UseQueryOptions<string, TokenValidationError>;

export const validateToken =
  (api: PutioAPIClient) =>
  async (token: string, scope: IValidateTokenResponse['token_scope']) => {
    try {
      const { data } = await api.Auth.ValidateToken(token);

      if (!data.result) {
        throw new TokenValidationError('invalid_token');
      }

      if (data.token_scope !== scope) {
        throw new TokenValidationError('invalid_scope');
      }

      return token;
    } catch (error) {
      throw new TokenValidationError('unknown', error);
    }
  };

export const useValidateToken = (
  token: string,
  scope: IValidateTokenResponse['token_scope'] = 'default',
  options: Options = {},
) => {
  const api = useAPI();

  const result = useQuery<string, TokenValidationError>({
    queryFn: () => validateToken(api)(token, scope),
    queryKey: ['validate-token', token, scope],
    ...options,
  });

  return result;
};
