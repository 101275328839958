import type { SelectModalOption } from '@/components/modal/select-modal';

import { translate } from '@putdotio/translations';

const options: SelectModalOption[] = [
  {
    label: translate('files_sort_name_label'),
    value: 'NAME',
  },
  {
    label: translate('files_sort_size_label'),
    value: 'SIZE',
  },
  {
    label: translate('files_sort_date_added_label'),
    value: 'DATE',
  },
  {
    label: translate('files_sort_date_modified_label'),
    value: 'MODIFIED',
  },
  {
    label: translate('files_sort_type_label'),
    value: 'TYPE',
  },
  {
    label: translate('files_sort_watch_status_label'),
    value: 'WATCH',
  },
];

const optionsWithDirections = options.reduce<SelectModalOption[]>(
  (options, option) => [
    ...options,
    {
      ...option,
      icon: 'chevron-up',
      value: `${option.value}_ASC`,
    },
    {
      ...option,
      icon: 'chevron-down',
      value: `${option.value}_DESC`,
    },
  ],
  [],
);

export const getOptionsForValue = (value: string): SelectModalOption[] => {
  const [sortKey, sortDirection] = value.split('_');

  return optionsWithDirections
    .filter((option) => {
      const [optionSortKey, optionSortDirection] = String(option.value).split(
        '_',
      );

      if (sortKey === optionSortKey) {
        return true;
      }

      return sortDirection === optionSortDirection;
    })
    .map((option) => {
      const [optionSortKey, optionSortDirection] = String(option.value).split(
        '_',
      );

      const showDisclosure =
        sortKey === optionSortKey && sortDirection !== optionSortDirection;

      return {
        ...option,
        icon: showDisclosure ? option.icon : undefined,
      };
    });
};
