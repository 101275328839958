import { useFocusEffect } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useRef } from "react";

export function useRefreshOnFocus(refetch: () => void) {
  const firstTimeRef = useRef(true);

  useFocusEffect(
    useCallback(() => {
      if (firstTimeRef.current) {
        firstTimeRef.current = false;
        return;
      }

      refetch();
    }, [refetch]),
  );
}

export function useRefreshOnMount(refetch: () => void) {
  useLayoutEffect(() => {
    refetch();
  }, [refetch]);
}

export function useEagerRefresh(refetch: () => void) {
  useRefreshOnMount(refetch);
  useRefreshOnFocus(refetch);
}
