import type { Theme } from "@/lib/theme";

import { Spacer } from "@/components/ui/spacer";
import { styled } from "@/lib/theme";
import { Fragment, useMemo } from "react";
import type { StyleProp, ViewStyle } from "react-native";

type StackProps = {
  align?: "baseline" | "center" | "flex-end" | "flex-start";
  children: React.ReactNode[];
  direction?: "column" | "row";
  flex?: number;
  justify?:
    | "center"
    | "flex-end"
    | "flex-start"
    | "space-around"
    | "space-between";
  spacing?: keyof Theme["spacing"];
  style?: StyleProp<ViewStyle>;
  width?: number | string;
};

export function Stack({
  align = "center",
  children,
  direction = "row",
  flex,
  justify = "flex-start",
  spacing = 0,
  style,
  width,
}: StackProps) {
  const Container = useMemo(
    () => styled.View`
      flex-direction: ${direction};
      justify-content: ${justify};
      align-items: ${align};
      ${width ? `width: ${width};` : ""}
      ${flex ? `flex: ${flex};` : ""}
    `,
    [direction, justify, align, width, flex],
  );

  const StackSpacer = useMemo(
    () => (
      <Spacer
        {...{
          x: direction === "row" ? spacing : 0,
          y: direction === "column" ? spacing : 0,
        }}
      />
    ),
    [direction, spacing],
  );

  const childrenWithKeys = useMemo(
    () => children.map((child, index) => ({ child, key: index })),
    [children],
  );

  return (
    <Container style={style}>
      {childrenWithKeys.map(({ child, key }) => (
        <Fragment key={key}>
          {child}
          {key < childrenWithKeys.length - 1 ? StackSpacer : null}
        </Fragment>
      ))}
    </Container>
  );
}

type XStackProps = Omit<StackProps, "align" | "direction"> & {
  align?: "bottom" | "center" | "top";
};

const xAlignMap: Record<string, StackProps["align"]> = {
  bottom: "flex-end",
  center: "center",
  top: "flex-start",
};

export function XStack({ align = "center", ...props }: XStackProps) {
  return <Stack align={xAlignMap[align]} direction="row" {...props} />;
}

type YStackProps = Omit<StackProps, "align" | "direction"> & {
  align?: "center" | "left" | "right";
};

const yAlignMap: Record<string, StackProps["align"]> = {
  center: "center",
  left: "flex-start",
  right: "flex-end",
};

export function YStack({ align = "left", ...props }: YStackProps) {
  return <Stack align={yAlignMap[align]} direction="column" {...props} />;
}
