import type { UseQueryOptions } from '@tanstack/react-query';

import { useQuery } from '@tanstack/react-query';

import { BFFError } from '../errors';
import { supabase } from '../supabase';

const fetchData = async (appID: number) => {
  const { data, error } = await supabase
    .from('apps')
    .select('*')
    .eq('id', appID);

  if (error) {
    throw new BFFError('Could not fetch remote app info');
  }

  if (!data || data.length === 0) {
    throw new BFFError('Could not find remote app info');
  }

  return data[0];
};

type RemoteAppInfo = Awaited<ReturnType<typeof fetchData>>;

export const useRemoteAppInfo = (
  appID: number,
  options: UseQueryOptions<RemoteAppInfo> = {},
) =>
  useQuery<RemoteAppInfo>({
    queryFn: () => fetchData(appID),
    queryKey: ['bff', 'app-info', appID],
    ...options,
  });
