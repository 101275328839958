import type { NotificationProps } from "@/components/notification";
import type { PropsWithChildren } from "react";

import { Notification } from "@/components/notification";
import { styled } from "@/lib/theme";
import { LocalizedError } from "@putdotio/utilities";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const ToastContainer = styled.View(({ theme }) => ({
  alignItems: "center",
  bottom: 0,
  left: 0,
  position: "absolute",
  width: "100%",
  zIndex: theme.zIndices.toast,
}));

const ToastNotificationContainer = styled.View(({ theme }) => ({
  marginBottom: theme.spacing.safe.bottom + theme.spacing.md,
  width: "40%",
}));

type ShowToastParams = LocalizedError | NotificationProps;

type ToastRef = {
  showToast: (params: ShowToastParams) => void;
};

type ToastRootProps = {
  children: React.ReactNode;
};

const ToastRoot = forwardRef(function ToastRootWithRef(
  props: ToastRootProps,
  ref,
) {
  const [toast, setToast] = useState<NotificationProps | null>(null);

  const showToast = useCallback<ToastRef["showToast"]>((params) => {
    if (params instanceof LocalizedError) {
      setToast({
        message: params.recoverySuggestion.description,
        title: params.message,
        type: "danger",
      });
    } else {
      setToast(params);
    }

    setTimeout(() => {
      setToast(null);
    }, 3000);
  }, []);

  const imperativeHandleCallback = useCallback(
    () => ({ showToast }),
    [showToast],
  );

  useImperativeHandle(ref, imperativeHandleCallback);

  return (
    <>
      {props.children}

      {toast ? (
        <ToastContainer>
          <ToastNotificationContainer>
            <Notification {...toast} />
          </ToastNotificationContainer>
        </ToastContainer>
      ) : null}
    </>
  );
});

const refs = new Set<ToastRef>();

export function ToastProvider({ children }: PropsWithChildren) {
  const toastRef = useRef<ToastRef | null>(null);

  const setRef = useCallback((ref: ToastRef | null) => {
    if (ref) {
      refs.add(ref);
    } else if (toastRef.current) {
      refs.delete(toastRef.current);
    }

    toastRef.current = ref;
  }, []);

  return <ToastRoot ref={setRef}>{children}</ToastRoot>;
}

type HackConfig = {
  delay: number;
};

export const toast = {
  show: (params: ShowToastParams, hack?: HackConfig) => {
    const run = () => {
      const lastRef = Array.from(refs).pop();

      if (lastRef) {
        lastRef.showToast(params);
      }
    };

    if (hack) {
      setTimeout(run, hack.delay);
    }

    run();
  },
};
