import type { IFile } from "@putdotio/api-client";

import { XStack } from "@/components";
import { Button } from "@/components/ui/button";
import { useModal } from "@/lib/contexts/modal";
import { useAPI } from "@putdotio/core";
import { useCallback } from "react";

import { FileSortOptionsModal } from "../modals/file-sort-options-modal";

type Props = {
  file: IFile;
  refetch: () => void;
};

function FolderActions({ file, refetch }: Props) {
  const api = useAPI();
  const { showModal } = useModal();

  const handleSort = useCallback(() => {
    showModal(({ hide }) => (
      <FileSortOptionsModal
        hide={hide}
        onSelect={async (sort_by) => {
          await api.post("/files/set-sort-by", {
            data: {
              file_id: file.id,
              sort_by,
            },
          });

          refetch();
        }}
        value={file.sort_by}
      />
    ));
  }, [showModal, file.sort_by, file.id, api, refetch]);

  return (
    <XStack spacing="sm">
      <Button icon="refresh-ccw" onPress={refetch} size="sm">
        Refresh
      </Button>

      <Button icon="arrow-down-wide-narrow" onPress={handleSort} size="sm">
        {file.sort_by.split("_")[0]}
      </Button>
    </XStack>
  );
}

export function FileActions(props: Props) {
  switch (props.file.file_type) {
    case "FOLDER":
      return <FolderActions {...props} />;

    default:
      return null;
  }
}
