import type { ColorKey } from '@putdotio/ui';
import type { ActivityIndicatorProps } from 'react-native';

import { useTheme } from '@/lib/theme';
import { useMemo } from 'react';
import {
  Platform,
  ActivityIndicator as RNActivityIndicator,
} from 'react-native';

type Props = ActivityIndicatorProps & {
  color?: ColorKey;
};

const style = {
  transform: [{ scale: Platform.OS === 'android' ? 2 : 1 }],
};

export function ActivityIndicator(props: Props) {
  const { colors } = useTheme();

  const color = useMemo(() => {
    if (props.color) {
      return colors[props.color];
    }
    return Platform.OS === 'android' ? colors.yellowSolid : colors.text;
  }, [colors, props.color]);

  return (
    <RNActivityIndicator
      {...props}
      color={color}
      style={[style, props.style]}
    />
  );
}
