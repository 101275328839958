import { Center } from '@/components/ui/center';
import { Smol } from '@/components/ui/text';
import { styled } from '@/lib/theme';
import { getAppInfo } from '@/lib/utils';

const Text = styled(Smol)`
  opacity: 0.33;
`;

type Props = {
  absolute?: boolean;
};

const AbsoluteContainer = styled.View({
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
});

export function AppInfo({ absolute = false }: Props) {
  const content = <Text color="textContrast">{getAppInfo().release}</Text>;

  return absolute ? (
    <AbsoluteContainer>
      <Center>{content}</Center>
    </AbsoluteContainer>
  ) : (
    content
  );
}
