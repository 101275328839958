import type { UseQueryOptions } from "@tanstack/react-query";

import { useAPI } from "@putdotio/core";
import { useQuery } from "@tanstack/react-query";

export type TunnelRoute = { description: string; name: string };

export const useTunnelRoutes = (
  options: UseQueryOptions<TunnelRoute[]> = {},
) => {
  const api = useAPI();

  const query = useQuery({
    queryFn: async () => {
      const { data } = await api.Tunnel.Routes();
      return data.routes as TunnelRoute[];
    },
    queryKey: ["tunnel-routes"],
    ...options,
  });

  return query;
};
