import { SelectModal } from '@/components/modal/select-modal';

import { getOptionsForValue } from './options';

type Props = {
  hide: () => void;
  onSelect: (value: number | string) => Promise<void>;
  value: string;
};

export function FileSortOptionsModal({ hide, onSelect, value }: Props) {
  const options = getOptionsForValue(value).filter(
    (option) => option.value !== value,
  );

  return (
    <SelectModal
      onHide={hide}
      onSelect={onSelect}
      options={options}
      title="Sort by"
    />
  );
}
