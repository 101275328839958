import { styled } from "@/lib/theme";

export const Overlay = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.colors["overlay-full"]};
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
