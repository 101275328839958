import { useAPI } from '@putdotio/core';
import { FileURLProvider } from '@putdotio/utilities';
import { useMemo } from 'react';

export const useFileURLProvider = () => {
  const api = useAPI();

  const fileURLProvider = useMemo(() => {
    return new FileURLProvider(api.options.baseURL!, api.token!);
  }, [api.options.baseURL, api.token]);

  return fileURLProvider;
};
