import type { ListItemProps } from "@/components";
import type { Config } from "@/features/config/lib";

import { SelectModal } from "@/components/modal/select-modal";
import { useConfigValue } from "@/features/config/lib";
import { useModal } from "@/lib/contexts/modal";
import { useNavigation } from "@/lib/navigation";
import {
  normalizeRouteDisplayName,
  useAccountSettings,
} from "@putdotio/features";
import { translate } from "@putdotio/translations";
import { Platform } from "react-native";

const getPlaybackTypeLabel = (playbackType: Config["playbackType"]) => {
  if (playbackType === "mp4") {
    return translate("video_playback_type_mp4");
  }

  return translate("video_playback_type_hls");
};

const getPlaybackBufferSizeLabel = (bufferSize: Config["bufferSize"]) => {
  if (bufferSize === "low") {
    return "Low";
  }

  if (bufferSize === "medium") {
    return "Medium";
  }

  return "High";
};

export const usePlaybackSettingsListItems = (): ListItemProps[] => {
  const { showModal } = useModal();
  const navigation = useNavigation();

  const [{ data: settings }, settingsMutation] = useAccountSettings();
  const [playbackType, setPlaybackType] = useConfigValue("playbackType");
  const [bufferSize, setBufferSize] = useConfigValue("bufferSize");

  return [
    {
      accessory: { type: "link" },
      description: normalizeRouteDisplayName(settings.tunnel_route_name),
      hasTVPreferredFocus: true,
      icon: { family: "lucide", name: "network" },
      onPress: () => navigation.navigate("tunnel"),
      title: translate("route_settings_title"),
    },

    {
      accessory: { type: "switch", value: settings.use_start_from },
      icon: { family: "lucide", name: "bookmark" },
      onPress: () =>
        settingsMutation.mutateAsync({
          use_start_from: !settings.use_start_from,
        }),
      title: translate("settings_form_remembervideoplace_label"),
    },

    Platform.OS === "ios"
      ? null
      : {
          accessory: { type: "link" },
          description: getPlaybackTypeLabel(playbackType),
          icon: { family: "lucide", name: "monitor-play" },
          onPress: () =>
            showModal(({ hide }) => (
              <SelectModal
                onHide={hide}
                onSelect={setPlaybackType}
                options={[
                  {
                    label: getPlaybackTypeLabel("mp4"),
                    value: "mp4",
                  },
                  {
                    label: getPlaybackTypeLabel("hls"),
                    value: "hls",
                  },
                ]}
                title={translate("video_playback_type_label")}
                value={playbackType}
              />
            )),
          title: translate("video_playback_type_label"),
        },

    Platform.OS === "ios"
      ? null
      : {
          accessory: { type: "link" },
          description: getPlaybackBufferSizeLabel(bufferSize),
          icon: { family: "lucide", name: "monitor-down" },
          onPress: () =>
            showModal(({ hide }) => (
              <SelectModal
                onHide={hide}
                onSelect={setBufferSize}
                options={(["low", "medium", "high"] as const).map((value) => ({
                  label: getPlaybackBufferSizeLabel(
                    value as Config["bufferSize"],
                  ),
                  value,
                }))}
                title="Playback buffer size"
                value={bufferSize}
              />
            )),
          title: "Video playback buffer size",
        },

    {
      accessory: { type: "switch", value: !settings.hide_subtitles },
      icon: { family: "lucide", name: "subtitles" },
      onPress: () =>
        settingsMutation.mutateAsync({
          hide_subtitles: !settings.hide_subtitles,
        }),
      title: translate("settings_form_show_subtitles_label"),
    },

    settings.hide_subtitles
      ? null
      : {
          accessory: {
            type: "switch",
            value: settings.dont_autoselect_subtitles,
          },
          icon: { family: "lucide", name: "list-checks" },
          onPress: () =>
            settingsMutation.mutateAsync({
              dont_autoselect_subtitles: !settings.dont_autoselect_subtitles,
            }),
          title: translate("settings_form_dont_autoselect_subtitles_label"),
        },
  ].filter(Boolean) as ListItemProps[];
};
