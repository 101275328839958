import type { UseQueryOptions } from '@tanstack/react-query';

import { useQuery } from '@tanstack/react-query';

import { supabase } from '../supabase';

const fetchTestStreams = async () => {
  const { data, error } = await supabase
    .from('test_streams')
    .select('*')
    .order('created_at', { ascending: true });

  if (error) {
    throw new Error('Could not fetch test streams');
  }

  return data;
};

export type QueryData = Awaited<ReturnType<typeof fetchTestStreams>>;

export const TEST_STREAMS_QUERY_KEY = ['bff', 'test-streams'];

export const useTestStreams = (options: UseQueryOptions<QueryData> = {}) =>
  useQuery({
    queryFn: fetchTestStreams,
    queryKey: TEST_STREAMS_QUERY_KEY,
    ...options,
  });
