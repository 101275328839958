// https://radix-ui.com/colors
// biome-ignore format: for readability
const SEMANTIC_COLOR_NAMES = [
  "bg",                   // 1  - main background (page, modal, etc.)
  "bg-secondary",         // 2  - secondary background (card, sidebar, etc.)
  "component-bg",         // 3  - interactive ui elements (button, input, etc.) also good for hover state of transparent elements
  "component-bg-hover",   // 4  - interactive ui elements (button, input, etc.) - hover state
  "component-bg-active",  // 5  - interactive ui elements (button, input, etc.) - active state
  "line",                 // 6  - borders of non-interactive ui elements (cards, etc.)
  "border",               // 7  - border of interactive ui elements
  "border-hover",         // 8  - border of interactive ui elements - hover state also focus ring
  "solid",                // 9  - solid color
  "solid-hover",          // 10 - solid color - hover state
  "text-secondary",       // 11 - secondary, low contrast text (labels, etc.)
  "text",                 // 12 - main, high contrast text (body, etc.)
] as const;

type SemanticColorName = (typeof SEMANTIC_COLOR_NAMES)[number];
type ColorNamespace = "green" | "red" | "yellow" | "yellow-alpha";
type MaybeColorNamespace = "" | ColorNamespace;

type NamespacedSemanticColorName<N extends MaybeColorNamespace> = N extends ""
  ? SemanticColorName
  : `${N}-${SemanticColorName}`;

export const generateSpectrumFromRadixColor = <N extends MaybeColorNamespace>(
  radixColorSpectrum: Record<string, string>,
  namespace: MaybeColorNamespace = "",
  overrides: Partial<Record<SemanticColorName, string>> = {},
): Record<NamespacedSemanticColorName<N>, string> => {
  const radixColors = Object.values(radixColorSpectrum);

  const colors = SEMANTIC_COLOR_NAMES.reduce<
    Partial<Record<NamespacedSemanticColorName<N>, string>>
  >((acc, semanticColorName, index) => {
    const colorName = (
      namespace ? `${namespace}-${semanticColorName}` : semanticColorName
    ) as NamespacedSemanticColorName<N>;

    acc[colorName] = radixColors[index];
    return acc;
  }, {});

  const nameSpacedOverrides = Object.entries(overrides).reduce<
    Partial<Record<NamespacedSemanticColorName<N>, string>>
  >((acc, [semanticColorName, value]) => {
    const colorName = (
      namespace ? `${namespace}-${semanticColorName}` : semanticColorName
    ) as NamespacedSemanticColorName<N>;

    acc[colorName] = value;
    return acc;
  }, {});

  return { ...colors, ...nameSpacedOverrides } as Record<
    NamespacedSemanticColorName<N>,
    string
  >;
};
