import type { GetPressableStyle } from "@/components/ui/pressable";
import type { Theme } from "@/lib/theme";
import type { PropsWithChildren } from "react";
import type { View } from "react-native";

import { LoadingOverlay } from "@/components/overlay";
import { Pressable } from "@/components/ui/pressable";
import { Text } from "@/components/ui/text";
import { styled, useTheme } from "@/lib/theme";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import type { IconProps } from "./icon";

import { Icon } from "./icon";
import { XStack } from "./stack";

type ButtonProps = PropsWithChildren<{
  hasTVPreferredFocus?: boolean;
  icon?: IconProps["name"];
  loading?: boolean;
  onFocus?: VoidFunction;
  onPress: VoidFunction;
  size?: "md" | "sm";
}>;

const useButtonStyles = (size: ButtonProps["size"] = "md") => {
  const theme = useTheme();

  switch (size) {
    case "sm":
      return {
        button: {
          fontSize: theme.text.caption.fontSize,
          spacing: "xs",
        },
        pressable: (focused: boolean) => ({
          backgroundColor: focused
            ? theme.colors["component-bg-active"]
            : theme.colors["component-bg"],
          borderColor: focused
            ? theme.colors["border-hover"]
            : theme.colors.border,
          paddingHorizontal: theme.spacing.sm,
          paddingVertical: theme.spacing.sm,
        }),
        text: {
          fontSize: theme.text.smol.fontSize,
        },
      };

    case "md":
      return {
        button: {
          fontSize: theme.text.body.fontSize,
          spacing: "sm",
        },
        pressable: (focused: boolean) => ({
          backgroundColor: focused
            ? theme.colors["component-bg-active"]
            : theme.colors["component-bg"],
          borderColor: focused
            ? theme.colors["border-hover"]
            : theme.colors.border,
          paddingHorizontal: theme.spacing.md,
          paddingVertical: theme.spacing.sm,
        }),
        text: {
          fontSize: theme.text.caption.fontSize,
        },
      };
  }
};

export function Button({
  children,
  hasTVPreferredFocus,
  icon,
  onFocus,
  onPress,
  size = "md",
  ...props
}: ButtonProps) {
  const ref = useRef<View>(null);
  const theme = useTheme();
  const styles = useButtonStyles(size);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      hasTVPreferredFocus &&
      ref.current &&
      typeof ref.current.setNativeProps === "function"
    ) {
      setTimeout(() => {
        ref.current?.setNativeProps({ hasTVPreferredFocus: true });
      }, 1);
    }
  }, [hasTVPreferredFocus]);

  const getPressableStyle = useCallback<GetPressableStyle>(
    ({ focused }) => ({
      alignItems: "center",
      borderRadius: theme.radii.default,
      borderWidth: 1,
      justifyContent: "center",
      overflow: "hidden",
      ...styles.pressable(focused),
    }),
    [theme.radii.default, styles],
  );

  const ButtonText = useMemo(
    () =>
      styled(Text)(({ theme }) => ({
        color: theme.colors.text,
        fontFamily: theme.text.label.fontFamily,
        textTransform: "uppercase",
        ...styles.text,
      })),
    [styles],
  );

  const handlePress = useCallback(async () => {
    try {
      setLoading(true);
      await onPress();
    } catch (e) {
      /* noop */
    } finally {
      setLoading(false);
    }
  }, [onPress]);

  const isLoading = useMemo(
    () => loading || props.loading,
    [loading, props.loading],
  );

  return (
    <Pressable
      onFocus={onFocus}
      onPress={handlePress}
      ref={ref}
      style={getPressableStyle}
      {...theme.pressable}
    >
      {isLoading ? (
        <LoadingOverlay color="transparent" indicatorColor="text" />
      ) : null}

      {icon ? (
        <XStack
          align="center"
          spacing={styles.button.spacing as keyof Theme["spacing"]}
          style={{ opacity: isLoading ? 0 : 1 }}
        >
          <Icon color="text" name={icon} size={styles.button.fontSize} />
          <ButtonText>{children}</ButtonText>
        </XStack>
      ) : (
        <ButtonText style={{ opacity: isLoading ? 0 : 1 }}>
          {children}
        </ButtonText>
      )}
    </Pressable>
  );
}
