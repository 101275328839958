import type { LocalizedError } from '@putdotio/utilities';

import { AppInfo } from '@/components/app-info';
import { Button } from '@/components/ui/button';
import { Center } from '@/components/ui/center';
import { YStack } from '@/components/ui/stack';
import { Heading, Text } from '@/components/ui/text';
import { useMemo } from 'react';

import { Icon } from './ui/icon';
import { Spacer } from './ui/spacer';

export function ErrorState({ error }: { error: LocalizedError }) {
  const recoverySuggestion = useMemo(() => {
    switch (error.recoverySuggestion.type) {
      case 'instruction':
      default:
        return (
          <Text align="center">{error.recoverySuggestion.description}</Text>
        );

      case 'action':
        return (
          <YStack align="center" spacing="lg">
            <Text>{error.recoverySuggestion.description}</Text>

            <Button
              hasTVPreferredFocus
              onPress={error.recoverySuggestion.trigger.callback}
            >
              {error.recoverySuggestion.trigger.label}
            </Button>
          </YStack>
        );
    }
  }, [error.recoverySuggestion]);

  return (
    <Center paddingX="xxl">
      <YStack align="center" spacing="md">
        <Icon color="redSolid" name="x-circle" size="heading" />
        <Heading>{error.message}</Heading>
        {recoverySuggestion}
        <Spacer y="lg" />
      </YStack>

      <AppInfo absolute />
    </Center>
  );
}
