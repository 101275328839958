import type { IFile } from "@putdotio/api-client";

import { useAPI } from "@putdotio/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type TrashQueryData = {
  cursor?: string;
  files: IFile[];
};

export const useTrash = () => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const queryKey = ["trash"];

  const query = useQuery<TrashQueryData>({
    queryFn: async () => (await api.Trash.List()).data,
    queryKey,
    suspense: true,
  });

  const restoreMutation = useMutation({
    mutationFn: (id: IFile["id"]) =>
      api.Trash.Restore({
        ids: [id],
      }),
    onSuccess: () => queryClient.invalidateQueries(queryKey),
  });

  const restoreAllMutation = useMutation({
    mutationFn: () => {
      const data = queryClient.getQueryData<TrashQueryData>(queryKey);
      if (!data) {
        throw new Error("No data");
      }

      const { cursor, files } = data;

      if (cursor) {
        return api.Trash.Restore({
          cursor,
        });
      }

      return api.Trash.Restore({
        ids: files.map((file) => file.id),
      });
    },
    onSuccess: () => queryClient.invalidateQueries(queryKey),
  });

  const deleteMutation = useMutation({
    mutationFn: (id: IFile["id"]) => api.Trash.Delete({ ids: [id] }),
    onSuccess: () => queryClient.invalidateQueries(queryKey),
  });

  const emptyMutation = useMutation({
    mutationFn: () => api.Trash.Empty(),
    onSuccess: () => queryClient.invalidateQueries(queryKey),
  });

  return [
    query,
    { deleteMutation, emptyMutation, restoreAllMutation, restoreMutation },
  ] as const;
};
