import type { GetPressableStyle } from "@/components/ui/pressable";

import { Smol, Text, XStack, YStack } from "@/components";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Pressable } from "@/components/ui/pressable";
import { SearchSettingsModal } from "@/features/search/modals/search-settings-modal";
import { useModal } from "@/lib/contexts/modal";
import { styled, useTheme } from "@/lib/theme";
import { useCallback, useRef, useState } from "react";
import { TextInput } from "react-native";

import { useSearchHistory } from "../../queries/search-history";

const SearchInput = styled(TextInput)({
  opacity: 0,
  position: "absolute",
});

const SearchHistoryButtonLabel = styled(Smol)({
  textTransform: "none",
});

export function SearchHeader({
  keyword,
  setKeyword,
}: {
  keyword: string;
  setKeyword: (keyword: string) => void;
}) {
  const { showModal } = useModal();
  const theme = useTheme();
  const inputRef = useRef<TextInput | null>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [searchHistory] = useSearchHistory();

  const handlePressablePress = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleInputFocus = useCallback(() => {
    setInputFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setInputFocused(false);
  }, []);

  const getPressableStyle = useCallback<GetPressableStyle>(
    ({ focused }) => ({
      backgroundColor:
        inputFocused || focused
          ? theme.colors["component-bg-active"]
          : theme.colors["component-bg"],
      borderColor:
        inputFocused || focused
          ? theme.colors["border-hover"]
          : theme.colors.border,
      borderRadius: theme.radii.default,
      borderWidth: 1,
      flex: 1,
      paddingHorizontal: theme.spacing.sm,
      paddingVertical: theme.spacing.sm,
    }),
    [theme, inputFocused],
  );

  return (
    <YStack align="left" spacing="sm" width="100%">
      <XStack align="bottom" spacing="sm" width="100%">
        <Pressable
          hasTVPreferredFocus
          onPress={handlePressablePress}
          style={getPressableStyle}
          {...theme.pressable}
        >
          <XStack spacing="xs" width="100%">
            <Icon name="search" size="label" />

            {keyword ? (
              <Text>{keyword}</Text>
            ) : (
              <Text color="text-secondary">Tap to start typing</Text>
            )}
          </XStack>

          <SearchInput
            onBlur={handleInputBlur}
            onChangeText={setKeyword}
            onFocus={handleInputFocus}
            placeholder="Search in put.io"
            ref={inputRef}
            returnKeyType="search"
            value={keyword}
          />
        </Pressable>

        <Button
          icon="settings"
          onPress={() => showModal(SearchSettingsModal)}
          size="sm"
        >
          Settings
        </Button>
      </XStack>

      {searchHistory.length > 0 ? (
        <XStack spacing="xs">
          {searchHistory.map((keyword) => (
            <Button
              icon="history"
              key={keyword}
              onPress={() => setKeyword(keyword)}
              size="sm"
            >
              <SearchHistoryButtonLabel>{keyword}</SearchHistoryButtonLabel>
            </Button>
          ))}
        </XStack>
      ) : null}
    </YStack>
  );
}
