import type { VideoPlayerProps } from "@/components/video-player";
import type { IFile } from "@putdotio/api-client";

import { useAccountSettings, useStartFrom } from "@putdotio/features";
import { useCallback } from "react";

export const useStartFromHandler = (fileID: IFile["id"]) => {
  const [{ data: settings }] = useAccountSettings();
  const [startFrom, setStartFrom] = useStartFrom(fileID, {
    enabled: settings.use_start_from,
  });

  const handleTimeUpdate = useCallback<
    NonNullable<VideoPlayerProps["onTimeUpdate"]>
  >(
    (currentTime) => {
      if (currentTime === 0) {
        return;
      }

      if (settings.use_start_from) {
        setStartFrom(currentTime);
      }
    },
    [setStartFrom, settings.use_start_from],
  );

  return [startFrom, handleTimeUpdate] as const;
};
