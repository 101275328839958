import type {
  IHistoryEvent,
  IHistoryResponse,
  IPutioAPIClientError,
} from '@putdotio/api-client';
import type { UseQueryOptions } from '@tanstack/react-query';

import { useAPI } from '@putdotio/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useHistoryEvents = (
  options: UseQueryOptions<
    IHistoryResponse['events'],
    IPutioAPIClientError
  > = {},
) => {
  const api = useAPI();
  const queryKey = ['history-events'];
  const queryClient = useQueryClient();

  const queryResponse = useQuery({
    queryFn: () => api.Events.Query().then((res) => res.data.events),
    queryKey,
    ...options,
  });

  const clearEventsMutation = useMutation({
    mutationFn: () => api.Events.Clear(),

    onError: (_err, _variables, context) => {
      if (context?.previousEvents) {
        queryClient.setQueryData(queryKey, context.previousEvents);
      }
    },

    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousEvents =
        queryClient.getQueryData<IHistoryEvent[]>(queryKey);
      queryClient.setQueryData(queryKey, []);

      return { previousEvents };
    },
  });

  const removeEventMutation = useMutation({
    mutationFn: (id: IHistoryEvent['id']) => api.Events.Delete(id),

    onError: (err, variables, context) => {
      if (context?.previousEvents) {
        queryClient.setQueryData(queryKey, context.previousEvents);
      }
    },

    onMutate: async (id: IHistoryEvent['id']) => {
      await queryClient.cancelQueries(queryKey);

      const previousEvents =
        queryClient.getQueryData<IHistoryEvent[]>(queryKey) ?? [];

      queryClient.setQueryData(
        queryKey,
        previousEvents.filter((e) => e.id !== id),
      );

      return { previousEvents };
    },
  });

  return [queryResponse, { clearEventsMutation, removeEventMutation }] as const;
};
