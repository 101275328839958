import { translate } from "@putdotio/translations";

import type { HistoryEventGroups } from "./groupEventsByDate";

export const getLabelForGroup = (group: keyof HistoryEventGroups | string) => {
  switch (group) {
    case "today":
      return translate("dashboard_today");

    case "yesterday":
      return translate("dashboard_yesterday");

    case "lastWeek":
      return translate("dashboard_lastweek");

    default:
      return translate("dashboard_ancienttimes");
  }
};
