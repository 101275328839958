import { useMemo } from "react";

import { useCreateAuthCode } from "../queries/create-auth-code";
import { useVerifyAuthCode } from "../queries/verify-auth-code";

export type AuthCodeAuthenticatorState =
  | { accessToken: string; status: "success" }
  | { code: string; status: "verifying-auth-code" }
  | { error: unknown; status: "error" }
  | { status: "creating-auth-code" };

export const useAuthCodeAuthenticator = (clientName: string) => {
  const createAuthCodeQuery = useCreateAuthCode(clientName);
  const verifyAuthCodeQuery = useVerifyAuthCode(createAuthCodeQuery.data);

  const state = useMemo<AuthCodeAuthenticatorState>(() => {
    switch (createAuthCodeQuery.status) {
      case "error":
        return { error: createAuthCodeQuery.error, status: "error" };

      case "success": {
        switch (verifyAuthCodeQuery.status) {
          case "error":
            return { error: verifyAuthCodeQuery.error, status: "error" };

          case "success":
            return { accessToken: verifyAuthCodeQuery.data, status: "success" };

          default:
            return {
              code: createAuthCodeQuery.data,
              status: "verifying-auth-code",
            };
        }
      }

      default:
        return { status: "creating-auth-code" };
    }
  }, [createAuthCodeQuery, verifyAuthCodeQuery]);

  return [state, { restart: createAuthCodeQuery.refetch }] as const;
};
