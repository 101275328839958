import Account from '@/features/account/screens/account';
import Tunnel from '@/features/account/screens/tunnel';
import Auth from '@/features/auth/screens/auth';
import AuthWithCode from '@/features/auth/screens/auth-with-code';
import AuthWithToken from '@/features/auth/screens/auth-with-token';
import Dev from '@/features/dev/screens/dev';
import TVSpaceNavigation from '@/features/dev/screens/tv-space-navigation';
import Diagnostics from '@/features/diagnostics/screens/diagnostics';
import DiagnosticsVideoPlayer from '@/features/diagnostics/screens/diagnostics-video-player';
import File from '@/features/files/screens/file';
import History from '@/features/history/screens/history';
import Home from '@/features/home/screens/home';
import Search from '@/features/search/screens/Search';
import Trash from '@/features/trash/screens/trash';
import { useAuthState } from '@putdotio/features';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import type { RootStackParamList } from './types';

import { NavigationContainer } from './container';

const Stack = createNativeStackNavigator<RootStackParamList>();

export function NavigationRoot() {
  const authState = useAuthState();

  return (
    <SafeAreaProvider>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            animation: 'fade',
            headerShown: false,
          }}
        >
          {authState.status === 'authenticated' ? (
            <Stack.Group>
              <Stack.Screen component={Home} name="home" />
              <Stack.Screen
                component={File}
                initialParams={{ id: 0 }}
                name="files"
              />
              <Stack.Screen component={Search} name="search" />
              <Stack.Screen component={History} name="history" />
              <Stack.Screen component={Account} name="account" />
              <Stack.Screen component={Trash} name="trash" />
              <Stack.Screen component={Tunnel} name="tunnel" />
              <Stack.Screen component={Diagnostics} name="diagnostics" />
              <Stack.Screen
                component={DiagnosticsVideoPlayer}
                name="diagnostics/video-player"
              />
              <Stack.Screen component={Dev} name="dev" />
              <Stack.Screen
                component={TVSpaceNavigation}
                name="dev/tv-space-navigation"
              />
            </Stack.Group>
          ) : (
            <Stack.Group>
              <Stack.Screen component={Auth} name="auth" />
              <Stack.Screen component={AuthWithCode} name="auth/with-code" />
              <Stack.Screen component={AuthWithToken} name="auth/with-token" />
            </Stack.Group>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  );
}
