import type { SelectModalOption } from "@/components/modal/select-modal";
import type { IFile } from "@putdotio/api-client";

import { SelectModal } from "@/components/modal/select-modal";
import { useModal } from "@/lib/contexts/modal";
import { toast } from "@/lib/contexts/toast";
import { localizeError } from "@/lib/errors";
import {
  TOGGLE_WATCH_STATUS_FEATURE_DISABLED_ERROR,
  useAccountSettings,
  useDeleteFile,
  useFileURLProvider,
  useToggleWatchStatus,
} from "@putdotio/features";
import { useCallback } from "react";
import { Linking } from "react-native";

type Option = Omit<SelectModalOption, "value"> & {
  enabled: boolean;
  onSelect: (file: IFile) => void;
};

export const useLongPressFileActions = () => {
  const { showModal } = useModal();
  const [{ data: settings }] = useAccountSettings();
  const { mutateAsync: deleteFile } = useDeleteFile();
  const { mutateAsync: toggleWatchStatus } = useToggleWatchStatus();
  const fileURLProvider = useFileURLProvider();

  const handleLongPress = useCallback(
    async (file: IFile) => {
      const streamURL = fileURLProvider.getStreamURL(file);

      const getOptions = async (): Promise<Option[]> => [
        {
          enabled: Boolean(streamURL) && (await Linking.canOpenURL("vlc://")),
          icon: "monitor-play",
          label: "Play in VLC",
          onSelect: () => Linking.openURL(`vlc://${streamURL}`),
        },

        // @TODO: ENABLE when Infuse starts supporting x-callback-url on tvOS
        // {
        //   enabled:
        //     Boolean(streamURL) && (await Linking.canOpenURL('infuse://')),
        //   icon: 'monitor-play',
        //   label: 'Play in Infuse',
        //   onSelect: () =>
        //     Linking.openURL(
        //       `infuse://x-callback-url/play?url=${encodeURIComponent(
        //         streamURL || '',
        //       )}`,
        //     ),
        // },

        {
          enabled:
            settings.use_start_from &&
            file.file_type === "VIDEO" &&
            !file.is_shared,
          icon: file.start_from > 0 ? "eye-off" : "eye",
          label: file.start_from > 0 ? "Mark as unwatched" : "Mark as watched",
          onSelect: toggleWatchStatus,
        },

        {
          destructive: true,
          enabled: !file.is_shared,
          icon: settings.trash_enabled ? "trash-2" : "delete",
          label: settings.trash_enabled ? "Trash" : "Delete",
          onSelect: (file) =>
            deleteFile(file, {
              onSuccess: () =>
                toast.show(
                  {
                    title: settings.trash_enabled
                      ? "Sent to trash!"
                      : "Deleted",
                    type: "success",
                  },
                  { delay: 1 },
                ),
            }),
        },
      ];

      try {
        const options = (await getOptions())
          .filter((option) => option.enabled)
          .map((option) => ({
            ...option,
            value: option.label.replace(/\s/g, "-").toLowerCase(),
          }));

        if (options.length === 0) {
          return;
        }

        showModal(({ hide }) => (
          <SelectModal
            onHide={hide}
            onSelect={(value) => {
              const option = options.find((option) => option.value === value);

              if (option) {
                return option.onSelect(file);
              }
            }}
            options={options}
            title={file.name}
          />
        ));
      } catch (e) {
        toast.show(
          localizeError(e, [TOGGLE_WATCH_STATUS_FEATURE_DISABLED_ERROR]),
        );
      }
    },
    [
      fileURLProvider,
      settings.use_start_from,
      settings.trash_enabled,
      toggleWatchStatus,
      deleteFile,
      showModal,
    ],
  );

  return handleLongPress;
};
