import type { PropsWithChildren } from "react";

import {
  QueryClient,
  QueryClientProvider,
  focusManager,
} from "@tanstack/react-query";
import { AppState, Platform } from "react-native";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  logger: {
    error: () => null,
    log: () => null,
    warn: () => null,
  },
});

function onAppStateChange(status: string) {
  if (Platform.OS !== "web") {
    focusManager.setFocused(status === "active");
  }
}

function useAppState() {
  const subscription = AppState.addEventListener("change", onAppStateChange);
  return () => subscription.remove();
}

export function QueryProvider({ children }: PropsWithChildren) {
  useAppState();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
