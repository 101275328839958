import type { ErrorLocalizerFn } from '@putdotio/utilities';
import type { FallbackProps } from 'react-error-boundary';

import { ErrorState } from '@/components/error-state';
import { LocalizedError } from '@putdotio/utilities';
import { type PropsWithChildren, useCallback } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { localizeError as defaultErrorLocalizer } from './localize-error';

export function ErrorBoundary({
  children,
  localizeError = defaultErrorLocalizer,
}: PropsWithChildren<{
  localizeError?: ErrorLocalizerFn;
}>) {
  const fallbackRender = useCallback(
    ({ error }: FallbackProps) => (
      <ErrorState
        error={error instanceof LocalizedError ? error : localizeError(error)}
      />
    ),
    [localizeError],
  );

  return (
    <ReactErrorBoundary fallbackRender={fallbackRender}>
      {children}
    </ReactErrorBoundary>
  );
}
