import type { VideoPlayerProps } from "@/components/video-player";
import type { UseConfigOptions as LibUseConfigOptions } from "@putdotio/features";

import {
  useConfig as libUseConfig,
  useConfigValue as libUseConfigValue,
} from "@putdotio/features";

export type Config = {
  bufferSize: "high" | "low" | "medium";
  playbackType: VideoPlayerProps["source"]["playbackType"];
  searchHistory: string[];
  searchHistoryEnabled: boolean;
};

const DEFAULT_CONFIG: Config = {
  bufferSize: "medium",
  playbackType: "hls",
  searchHistory: [],
  searchHistoryEnabled: true,
};

type UseConfigOptions = Omit<LibUseConfigOptions<Config>, "initialData">;

export const useConfig = (options: UseConfigOptions = {}) => {
  const config = libUseConfig<Config>({
    initialData: DEFAULT_CONFIG,
    initialDataUpdatedAt: 0,
    staleTime: 60 * 60 * 1000, // 1 hour
    ...options,
  });

  return config;
};

export const useConfigValue = <Key extends keyof Config>(
  key: Key,
  options: UseConfigOptions = {},
) => {
  const [value, setValue] = libUseConfigValue<Config, Key>(key, {
    initialData: DEFAULT_CONFIG,
    initialDataUpdatedAt: 0,
    staleTime: 60 * 60 * 1000, // 1 hour
    ...options,
  });

  return [value, setValue] as const;
};
