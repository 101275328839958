import type { IHistoryEvent } from "@putdotio/api-client";

import { daysDiffFromNow } from "@putdotio/utilities";

export type HistoryEventGroups<EventType = IHistoryEvent> = {
  ancientTimes: EventType[];
  lastWeek: EventType[];
  today: EventType[];
  yesterday: EventType[];
};

const historyEventGroupsOrder: Array<keyof HistoryEventGroups> = [
  "today",
  "yesterday",
  "lastWeek",
  "ancientTimes",
];

export const groupEventsByDate = <EventType extends IHistoryEvent>(
  events: EventType[],
) => {
  const groupedEvents = events.reduce<HistoryEventGroups<EventType>>(
    (groups, event) => {
      const diff = daysDiffFromNow(event.created_at);

      if (diff === 0) {
        groups.today.push(event);
      } else if (diff === 1) {
        groups.yesterday.push(event);
      } else if (diff < 8) {
        groups.lastWeek.push(event);
      } else {
        groups.ancientTimes.push(event);
      }

      return groups;
    },
    {
      ancientTimes: [],
      lastWeek: [],
      today: [],
      yesterday: [],
    },
  );

  return historyEventGroupsOrder.reduce<
    Array<{
      events: EventType[];
      group: keyof HistoryEventGroups;
    }>
  >((acc, key) => {
    const result = acc.concat([
      {
        events: groupedEvents[key],
        group: key,
      },
    ]);

    return result;
  }, []);
};
