import type { IAccountInfo } from '@putdotio/api-client';
import type PutioAPIClient from '@putdotio/api-client';

import { useAPI } from '@putdotio/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

const createFetchAccountInfo = (api: PutioAPIClient) => async () => {
  const { data } = await api.Account.Info({
    download_token: 1,
    features: 1,
    intercom: 1,
    pas: 1,
    platform: 'web',
    push_token: 1,
    sharing: 1,
  });

  return data.info;
};

export const ACCOUNT_INFO_QUERY_KEY = ['account-info'];

export const useAccountInfo = () => {
  const api = useAPI();

  const result = useQuery<IAccountInfo>({
    queryFn: () => createFetchAccountInfo(api)(),
    queryKey: ACCOUNT_INFO_QUERY_KEY,
    staleTime: Infinity,
  });

  if (result.data === undefined) {
    throw new Error(
      'accountInfo is not available, please make sure it is prefetched.',
    );
  }

  return result;
};

export const usePrefetchAccountInfo = () => {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useCallback(
    () =>
      queryClient.prefetchQuery({
        queryFn: () => createFetchAccountInfo(api)(),
        queryKey: ACCOUNT_INFO_QUERY_KEY,
      }),
    [api, queryClient],
  );
};
