import type { ErrorLocalizerFn } from "@putdotio/utilities";
import type { ViewStyle } from "react-native";

import { LoadingScreen } from "@/components";
import { ErrorBoundary } from "@/lib/errors";
import { Suspense } from "react";
import Animated, { FadeIn } from "react-native-reanimated";

const viewStyle: ViewStyle = {
  flex: 1,
};

export const withSuspense = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>,
  {
    localizeError,
  }: {
    localizeError?: ErrorLocalizerFn;
  } = {},
): React.FC<P> =>
  function WithSuspense(props) {
    return (
      <ErrorBoundary localizeError={localizeError}>
        <Suspense
          fallback={
            <Animated.View entering={FadeIn.delay(300)} style={viewStyle}>
              <LoadingScreen />
            </Animated.View>
          }
        >
          <Animated.View entering={FadeIn} style={viewStyle}>
            <WrappedComponent {...props} />
          </Animated.View>
        </Suspense>
      </ErrorBoundary>
    );
  };
