import type { View } from "react-native";

import { useTVRemoteEventHandler } from "@/lib/hooks/use-tv-remote-event-handler";
import { styled, useTheme } from "@/lib/theme";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { doNothing } from "remeda";

import type { IconProps } from "./ui/icon";
import type { GetPressableStyle } from "./ui/pressable";

import { ActivityIndicator } from "./ui/activity-indicator";
import { Icon } from "./ui/icon";
import { Pressable } from "./ui/pressable";
import { XStack, YStack } from "./ui/stack";
import { Switch } from "./ui/switch";
import { Caption, Text } from "./ui/text";

type ListItemAccessory =
  | { props: IconProps; type: "icon" }
  | { type: "link" }
  | { type: "switch"; value: boolean }
  | undefined;

export type ListItemProps = {
  accessory?: ListItemAccessory;
  description?: string;
  hasTVPreferredFocus?: boolean;
  icon: IconProps | IconProps["name"];
  onLongPress?: () => Promise<void> | void;
  onPress?: () => Promise<void> | void;
  title: string;
};

const Description = styled(Caption)(({ theme }) => ({
  color: theme.colors["text-secondary"],
  marginTop: theme.spacing.xxs,
}));

const AccessoryContainer = styled.View({
  height: 50,
  justifyContent: "center",
  marginLeft: "auto",
});

function Accessory({
  accessory,
  loading,
}: {
  accessory: Exclude<ListItemAccessory, "undefined">;
  loading: boolean;
}) {
  const theme = useTheme();

  const content = useMemo(() => {
    if (!accessory) {
      return null;
    }

    switch (accessory.type) {
      case "link":
        return <Icon name="chevron-right" size={theme.text.body.fontSize} />;

      case "icon":
        return <Icon size={theme.text.label.fontSize} {...accessory.props} />;

      case "switch":
        return <Switch value={accessory.value} />;

      default:
        return null;
    }
  }, [accessory, theme]);

  const contentWithLoading = useMemo(() => {
    if (!loading) {
      return content;
    }

    if (!content) {
      return <ActivityIndicator />;
    }

    if (loading) {
      return (
        <XStack spacing="md">
          <ActivityIndicator />
          {content}
        </XStack>
      );
    }
  }, [content, loading]);

  return contentWithLoading ? (
    <AccessoryContainer>{contentWithLoading}</AccessoryContainer>
  ) : null;
}

export function ListItem({
  accessory,
  description,
  hasTVPreferredFocus,
  icon,
  onLongPress = doNothing(),
  onPress = doNothing(),
  title,
}: ListItemProps) {
  const ref = useRef<View>(null);
  const theme = useTheme();
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      hasTVPreferredFocus &&
      ref.current &&
      typeof ref.current.setNativeProps === "function"
    ) {
      setTimeout(() => {
        ref.current?.setNativeProps({ hasTVPreferredFocus: true });
      }, 1);
    }
  }, [hasTVPreferredFocus]);

  const handleFocus = useCallback(() => setFocused(true), []);

  const handleBlur = useCallback(() => setFocused(false), []);

  const handlePress = useCallback(async () => {
    try {
      setLoading(true);
      await onPress();
    } finally {
      setLoading(false);
    }
  }, [onPress]);

  const handleLongPress = useMemo(
    () =>
      focused
        ? debounce(onLongPress, 500, {
            leading: true,
            trailing: false,
          })
        : doNothing(),
    [focused, onLongPress],
  );

  useTVRemoteEventHandler({
    onLongPress: handleLongPress,
    onRightPress: handleLongPress,
  });

  const getPressableStyle = useCallback<GetPressableStyle>(
    ({ focused }) => ({
      backgroundColor: focused
        ? theme.colors["component-bg-active"]
        : "transparent",
      borderRadius: theme.radii.default,
      marginLeft: theme.spacing.safe.left,
      marginRight: theme.spacing.safe.right,
      padding: theme.spacing.md,
    }),
    [theme],
  );

  return (
    <Pressable
      onBlur={handleBlur}
      onFocus={handleFocus}
      onPress={handlePress}
      ref={ref}
      style={getPressableStyle}
      {...theme.pressable}
    >
      <XStack spacing="md">
        {typeof icon === "string" ? (
          <Icon
            color="yellow-solid"
            name={icon}
            size={theme.text.label.fontSize}
          />
        ) : (
          <Icon
            color="yellow-solid"
            size={theme.text.label.fontSize}
            {...icon}
          />
        )}

        <YStack flex={1}>
          <Text numberOfLines={1}>{title}</Text>

          {description ? (
            <Description numberOfLines={1}>{description}</Description>
          ) : null}
        </YStack>

        <Accessory accessory={accessory} loading={loading} />
      </XStack>
    </Pressable>
  );
}
