import type { TunnelRoute } from "@putdotio/features";

import { ListItem, Screen } from "@/components";
import { withSuspense } from "@/lib/hocs/with-suspense";
import {
  normalizeRouteDisplayName,
  normalizeRouteName,
  useAccountSettings,
  useTunnelRoutes,
} from "@putdotio/features";
import { translate } from "@putdotio/translations";
import { useCallback } from "react";
import { FlatList } from "react-native";

function TunnelScreen() {
  const [{ data: settings }, { mutate }] = useAccountSettings();
  const routesQuery = useTunnelRoutes({ suspense: true });

  const renderItem = useCallback(
    ({ item }: { item: TunnelRoute }) => {
      const selected =
        item.name === normalizeRouteName(settings.tunnel_route_name);

      return (
        <ListItem
          hasTVPreferredFocus={selected}
          icon={{ color: "text", name: selected ? "check-circle-2" : "circle" }}
          onPress={() => mutate({ tunnel_route_name: item.name })}
          title={normalizeRouteDisplayName(item.name)}
        />
      );
    },
    [settings.tunnel_route_name, mutate],
  );

  return (
    <Screen title={translate("route_settings_title")}>
      <FlatList data={routesQuery.data} renderItem={renderItem} />
    </Screen>
  );
}

export default withSuspense(TunnelScreen);
