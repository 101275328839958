import type { PropsWithChildren } from 'react';

import { UpdateNotifierModal } from '@/components/modal/update-notifier-modal';
import { useRemoteConfig } from '@/lib/queries/remote-config';
import { getAppInfo } from '@/lib/utils';
import { useRemoteAppInfo } from '@putdotio/features';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useModal } from './modal';

const useUpdateNotifierStorage = () => {
  const result = useQuery({
    queryFn: async () => {
      const buildNumber =
        (await AsyncStorage.getItem('@putio:update-notified-for')) || '0';

      return parseInt(buildNumber, 10);
    },
    queryKey: ['update-notifier'],
  });

  const setUpdateNotifierStorage = useMutation({
    mutationFn: (buildNumber: number) =>
      AsyncStorage.setItem(
        '@putio:update-notified-for',
        buildNumber.toString(),
      ),
  });

  return [result, setUpdateNotifierStorage.mutate] as const;
};

export function UpdateNotifierProvider({ children }: PropsWithChildren) {
  const app = getAppInfo();
  const remoteConfigQuery = useRemoteConfig();
  const remoteAppInfoQuery = useRemoteAppInfo(app.id, {
    staleTime: 60 * 60 * 1, // 1 hour
  });
  const [updateNotifiedForQuery, setUpdateNotifiedFor] =
    useUpdateNotifierStorage();

  const { showModal } = useModal();

  useEffect(() => {
    if (
      remoteConfigQuery.data.updateNotifierEnabled &&
      updateNotifiedForQuery.status === 'success' &&
      remoteAppInfoQuery.status === 'success'
    ) {
      const remoteAppInfo = remoteAppInfoQuery.data;

      if (
        updateNotifiedForQuery.data !== remoteAppInfo.build_number &&
        app.buildNumber < remoteAppInfo.build_number
      ) {
        setUpdateNotifiedFor(remoteAppInfo.build_number);
        showModal(() => <UpdateNotifierModal />);
      }
    }
  }, [
    updateNotifiedForQuery.status,
    updateNotifiedForQuery.data,
    remoteAppInfoQuery.status,
    remoteAppInfoQuery.data,
    remoteConfigQuery.data,
    setUpdateNotifiedFor,
    app.buildNumber,
    showModal,
  ]);

  return <>{children}</>;
}
