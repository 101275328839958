import type { NavScreenProps } from '@/lib/navigation/types';

import {
  ActivationCode,
  AppInfo,
  Center,
  ErrorScreen,
  Heading,
  Label,
  LoadingScreen,
  Screen,
  Text,
  YStack,
} from '@/components';
import { Button } from '@/components/ui/button';
import { localizeError } from '@/lib/errors';
import { styled } from '@/lib/theme';
import { getDeviceInfo } from '@/lib/utils';
import { useAuthCodeAuthenticator } from '@putdotio/features';
import { useLayoutEffect } from 'react';

const AppLink = styled(Label)`
  color: ${(props) => props.theme.colors.yellowSolid};
`;

function AuthWithCode({ navigation }: NavScreenProps<'auth/with-code'>) {
  const [state, { restart }] = useAuthCodeAuthenticator(getDeviceInfo().name);

  useLayoutEffect(() => {
    if (state.status === 'success') {
      navigation.replace('auth/with-token', { token: state.accessToken });
    }
  }, [navigation, state]);

  switch (state.status) {
    case 'creating-auth-code':
      return <LoadingScreen title="Getting code" />;

    case 'verifying-auth-code':
      return (
        <Screen>
          <Center>
            <YStack align="center" spacing="xl">
              <Heading>┌( ಠ‿ಠ)┘ welcome!</Heading>

              <YStack align="center" spacing="sm">
                <Text>use this activation code to log in</Text>
                <ActivationCode code={state.code} />
              </YStack>

              <YStack align="center" spacing="xs">
                <Text>follow the steps at the website below</Text>
                <AppLink>put.io/link</AppLink>
              </YStack>

              <Button
                hasTVPreferredFocus
                icon="refresh-ccw"
                key={state.code}
                onPress={restart}
              >
                Get new code
              </Button>
            </YStack>
          </Center>

          <AppInfo absolute />
        </Screen>
      );

    case 'error': {
      const error = localizeError(state.error, [
        {
          kind: 'api_status_code',
          localize: () => ({
            message: 'Expired code',
            recoverySuggestion: {
              description:
                'Seems like your code has expired. Please get a new one and try again.',
              trigger: {
                callback: restart,
                label: 'Get new code',
              },
              type: 'action',
            },
          }),
          status_code: 404,
        },
      ]);

      return <ErrorScreen error={error} />;
    }

    default:
      return null;
  }
}

export default AuthWithCode;
