import type { Theme } from "@/lib/theme";

import { styled } from "@/lib/theme";

type Props = {
  height?: number | string;
  paddingX?: keyof Theme["spacing"];
  paddingY?: keyof Theme["spacing"];
  width?: number | string;
};

export const Center = styled.View<Props>((props) => ({
  alignItems: "center",
  height: props.height || "100%",
  justifyContent: "center",
  paddingHorizontal: props.paddingX ? props.theme.spacing[props.paddingX] : 0,
  paddingVertical: props.paddingY ? props.theme.spacing[props.paddingY] : 0,
  width: props.width || "100%",
}));
