import type { SelectModalOption } from '@/components/modal/select-modal';
import type { FileListProps } from '@/features/files/components/file-list';
import type { IFile } from '@putdotio/api-client';

import { EmptyState, Screen, XStack } from '@/components';
import { SelectModal } from '@/components/modal/select-modal';
import { Button } from '@/components/ui/button';
import { FileList } from '@/features/files/components/file-list';
import { useModal } from '@/lib/contexts/modal';
import { withSuspense } from '@/lib/hocs/with-suspense';
import { translate } from '@putdotio/translations';
import { useCallback } from 'react';

import { useTrash } from '../queries/trash';

function Trash() {
  const { showModal } = useModal();

  const [
    query,
    { deleteMutation, emptyMutation, restoreAllMutation, restoreMutation },
  ] = useTrash();

  if (!query.data) {
    throw new Error('No data');
  }

  const handlePress = useCallback<NonNullable<FileListProps['onPress']>>(
    (file) => {
      const options: Array<
        SelectModalOption & {
          onSelect: (id: IFile['id']) => void;
        }
      > = [
        {
          icon: 'history',
          label: 'Restore',
          onSelect: restoreMutation.mutateAsync,
          value: 'restore',
        },
        {
          destructive: true,
          icon: 'delete',
          label: 'Delete',
          onSelect: deleteMutation.mutateAsync,
          value: 'delete',
        },
      ];

      showModal(({ hide }) => (
        <SelectModal
          onHide={hide}
          onSelect={(value) => {
            const option = options.find((option) => option.value === value);

            if (option) {
              return option.onSelect(file.id);
            }
          }}
          options={options}
          title={file.name}
        />
      ));
    },
    [restoreMutation.mutateAsync, deleteMutation.mutateAsync, showModal],
  );

  if (query.data.files.length === 0) {
    return (
      <Screen title="Trash">
        <EmptyState
          icon="delete"
          message={translate('trash_no_files_message')}
          title={translate('trash_no_files_title')}
        />
      </Screen>
    );
  }

  return (
    <Screen
      actions={
        <XStack spacing="sm">
          <Button
            icon="history"
            onPress={restoreAllMutation.mutateAsync}
            size="sm"
          >
            Restore all
          </Button>

          <Button icon="trash" onPress={emptyMutation.mutateAsync} size="sm">
            Empty
          </Button>
        </XStack>
      }
      title="Trash"
    >
      <FileList
        files={query.data?.files}
        hasTVPreferredFocus
        onPress={handlePress}
      />
    </Screen>
  );
}

export default withSuspense(Trash);
