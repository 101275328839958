import { ProgressView, Smol, YStack } from '@/components';
import { styled, useTheme } from '@/lib/theme';
import { useDiskUsage } from '@putdotio/features';
import { View } from 'react-native';

const DiskUsageLabel = styled(Smol)(({ theme }) => ({
  color: theme.colors.textContrast,
}));

const progressStyle = {
  height: 10,
};

export function DiskUsage() {
  const { disk, label } = useDiskUsage();
  const theme = useTheme();

  return (
    <YStack align="left" spacing="xs">
      <DiskUsageLabel>{label}</DiskUsageLabel>

      <View style={{ flex: 1, minWidth: 250, width: '100%' }}>
        <ProgressView
          progress={disk.used / disk.size}
          progressTintColor={theme.colors.yellowSolid}
          style={progressStyle}
          trackTintColor={theme.colors.solid}
        />
      </View>
    </YStack>
  );
}
