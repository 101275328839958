import type { ListItemProps } from "@/components";
import type { NavScreenProps } from "@/lib/navigation/types";

import { ListItem, Screen } from "@/components";
import { Logo } from "@/components/ui/logo";
import { useConfig } from "@/features/config/lib";
import { useRemoteConfig } from "@/lib/queries/remote-config";
import { Sentry } from "@/lib/sentry";
import { useAccountInfo, useAccountSettings } from "@putdotio/features";
import { useEffect } from "react";
import { FlatList } from "react-native";

function Home({ navigation }: NavScreenProps<"home">) {
  const { data: accountInfo } = useAccountInfo();
  const [{ data: settings }] = useAccountSettings();
  const { data: config } = useConfig();
  const { data: remoteConfig } = useRemoteConfig();

  useEffect(() => {
    Sentry.setUser({
      id: accountInfo.user_id.toString(),
      username: accountInfo.username,
    });

    Sentry.setExtra("userConfig", config);
    Sentry.setExtra("remoteConfig", remoteConfig);
  }, [accountInfo, config, remoteConfig]);

  const items: Array<ListItemProps & { enabled: boolean }> = [
    {
      accessory: { type: "link" },
      enabled: true,
      hasTVPreferredFocus: true,
      icon: "folder-closed",
      onPress: () => {
        navigation.push("files", { id: 0 });
      },
      title: "Your Files",
    },

    {
      accessory: { type: "link" },
      enabled: true,
      icon: "search",
      onPress: () => {
        navigation.push("search");
      },
      title: "Search",
    },

    {
      accessory: { type: "link" },
      enabled: settings.history_enabled,
      icon: "history",
      onPress: () => {
        navigation.push("history");
      },
      title: "History",
    },

    {
      accessory: { type: "link" },
      enabled: true,
      icon: "user",
      onPress: () => {
        navigation.push("account");
      },
      title: "Account",
    },

    {
      accessory: { type: "link" },
      enabled: accountInfo.user_id === 7,
      icon: "drafting-compass",
      onPress: () => {
        navigation.push("dev");
      },
      title: "Developer",
    },
  ];

  return (
    <Screen header={<Logo />}>
      <FlatList
        data={items.filter((item) => item.enabled)}
        renderItem={({ item }) => <ListItem {...item} />}
      />
    </Screen>
  );
}

export default Home;
