import type { IFile } from "@putdotio/api-client";

import { useAPI } from "@putdotio/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getFileInfoQueryKey } from "../queries/file-info";

export const useDeleteFile = () => {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation((file: IFile) => api.Files.Delete([file.id]), {
    onSuccess: (_, file) => {
      queryClient.invalidateQueries(getFileInfoQueryKey(file.parent_id));
    },
  });
};

export const useToggleWatchStatus = () => {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation(
    (file: IFile) =>
      api.Files.SetWatchStatus({
        ids: [file.id],
        watched: !(file.start_from > 0),
      }),
    {
      onSuccess: (_, file) => {
        queryClient.invalidateQueries(getFileInfoQueryKey(file.parent_id));
      },
    },
  );
};
