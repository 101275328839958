import { ActivityIndicator } from '@/components/ui/activity-indicator';
import { Center } from '@/components/ui/center';
import { YStack } from '@/components/ui/stack';
import { Heading } from '@/components/ui/text';

import { Spacer } from './ui/spacer';

export function LoadingScreen({ title = 'Loading' }: { title?: string }) {
  return (
    <Center>
      <YStack align="center" spacing="md">
        <Heading>{title}</Heading>
        <ActivityIndicator size="large" />
        <Spacer y="md" />
      </YStack>
    </Center>
  );
}
