import type { FileListProps } from '@/features/files/components/file-list';
import type { NavScreenProps } from '@/lib/navigation/types';

import { Center, ErrorState, Screen, Text, XStack } from '@/components';
import { ActivityIndicator } from '@/components/ui/activity-indicator';
import { FileList } from '@/features/files/components/file-list';
import { useLongPressFileActions } from '@/features/files/hooks/use-long-press-file-actions';
import { localizeError } from '@/lib/errors';
import { useFileSearch } from '@putdotio/features';
import { useCallback, useMemo, useState } from 'react';

import { useAddKeywordToSearchHistory } from '../../queries/search-history';
import { SearchHeader } from './search-header';

function SearchScreen({ navigation }: NavScreenProps<'search'>) {
  const [keyword, setKeyword] = useState('');
  const searchQuery = useFileSearch(keyword);
  const addKeywordToSearchHistory = useAddKeywordToSearchHistory();

  const handlePress = useCallback<NonNullable<FileListProps['onPress']>>(
    (file) => {
      addKeywordToSearchHistory(keyword);
      navigation.push('files', { id: file.id });
    },
    [addKeywordToSearchHistory, keyword, navigation],
  );

  const handleLongPress = useLongPressFileActions();

  const results = useMemo(() => {
    if (searchQuery.isFetching) {
      return (
        <Center height="auto">
          <XStack spacing="sm">
            <ActivityIndicator size="large" />
            <Text>Searching...</Text>
          </XStack>
        </Center>
      );
    }

    switch (searchQuery.status) {
      case 'success': {
        if (searchQuery.data.files.length === 0) {
          return (
            <Center height="auto">
              <Text>No results found.</Text>
            </Center>
          );
        }

        return (
          <FileList
            files={searchQuery.data.files}
            hasTVPreferredFocus={false}
            onLongPress={handleLongPress}
            onPress={handlePress}
          />
        );
      }

      case 'error':
        return <ErrorState error={localizeError(searchQuery.error)} />;

      default:
        return null;
    }
  }, [searchQuery, handlePress, handleLongPress]);

  return (
    <Screen header={<SearchHeader keyword={keyword} setKeyword={setKeyword} />}>
      {results}
    </Screen>
  );
}

export default SearchScreen;
