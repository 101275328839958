import { ExtendableError } from "@putdotio/utilities";

export class TokenValidationError extends ExtendableError {
  type: "invalid_scope" | "invalid_token" | "unknown";

  underlyingError?: unknown;

  constructor(
    type: TokenValidationError["type"],
    underlyingError?: TokenValidationError["underlyingError"],
  ) {
    super();
    this.type = type;
    this.underlyingError = underlyingError;
  }
}
