import { styled } from '@/lib/theme';

type Props = {
  progress: number;
  progressTintColor: string;
  style?: any;
  trackTintColor: string;
};

const Container = styled.View<{
  trackTintColor: string;
}>(({ trackTintColor }) => ({
  backgroundColor: trackTintColor,
  borderRadius: 4,
  overflow: 'hidden',
}));

const Progress = styled.View<{ progress: number; progressTintColor: string }>(
  ({ progress, progressTintColor }) => ({
    backgroundColor: progressTintColor,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: `${progress * 100}%`,
  }),
);

export function ProgressView({
  progress,
  progressTintColor,
  style,
  trackTintColor,
}: Props) {
  return (
    <Container style={style} trackTintColor={trackTintColor as string}>
      <Progress
        progress={progress || 0}
        progressTintColor={progressTintColor as string}
      />
    </Container>
  );
}
