import type { ListItemProps } from "@/components";
import type { NavScreenProps } from "@/lib/navigation/types";

import { ListItem, Screen } from "@/components";
import { FlatList } from "react-native";

export default function DevScreen({ navigation }: NavScreenProps<"dev">) {
  const items: ListItemProps[] = [
    {
      accessory: { type: "link" },
      hasTVPreferredFocus: true,
      icon: "drafting-compass",
      onPress: () => {
        navigation.push("dev/tv-space-navigation");
      },
      title: "tv-space-navigation",
    },
  ];

  return (
    <Screen title="Developer">
      <FlatList
        data={items}
        renderItem={({ item }) => <ListItem {...item} />}
      />
    </Screen>
  );
}
