import { useTheme } from "@/lib/theme";
import { Switch as RNSwitch } from "react-native";

import type { SwitchProps } from "./Switch";

export function Switch({ value }: SwitchProps) {
  const theme = useTheme();

  return (
    <RNSwitch
      thumbColor={value ? theme.colors["green-solid"] : "white"}
      trackColor={{
        false: theme.colors.solid,
        true: theme.colors["green-border"],
      }}
      value={value}
    />
  );
}
