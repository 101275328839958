import type { Color } from '@putdotio/ui';
import type { TextProps as RNTextProps } from 'react-native';

import { styled, useTheme } from '@/lib/theme';
import { useMemo } from 'react';
import { Text as RNText } from 'react-native';

type TextProps = RNTextProps & {
  align?: 'center' | 'left' | 'right';
  color?: Color;
  fontSize?: number;
};

export function Text({ align, children, ...props }: TextProps) {
  const theme = useTheme();
  const { color, fontSize } = props;

  const StyledText = useMemo(
    () => styled(RNText)`
      font-size: ${fontSize ?? theme.text.body.fontSize};
      font-family: ${theme.text.body.fontFamily};
      text-align: ${align ?? 'left'};
      color: ${color ? theme.colors[color] : theme.text.body.color};
    `,
    [color, fontSize, theme, align],
  );

  return (
    <StyledText {...props} accessible={false}>
      {children}
    </StyledText>
  );
}

export const Heading = styled(Text)(({ color, theme: { colors, text } }) => ({
  color: color ? colors[color] : text.heading.color,
  fontFamily: text.heading.fontFamily,
  fontSize: text.heading.fontSize,
}));

export const Label = styled(Text)`
  font-size: ${({ theme }) => theme.text.label.fontSize};
  font-family: ${({ theme }) => theme.text.label.fontFamily};
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.text.label.color};
`;

export const Caption = styled(Text)`
  font-size: ${({ theme }) => theme.text.caption.fontSize};
  font-family: ${({ theme }) => theme.text.caption.fontFamily};
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.text.caption.color};
`;

export const Smol = styled(Text)`
  font-size: ${({ theme }) => theme.text.smol.fontSize};
  font-family: ${({ theme }) => theme.text.smol.fontFamily};
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.text.smol.color};
`;
