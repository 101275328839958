import { styled } from "@/lib/theme";

import { AppInfo } from "./app-info";
import { Screen } from "./screen";
import { Center } from "./ui/center";
import { Logo } from "./ui/logo";

const Container = styled.View(({ theme }) => ({
  backgroundColor: theme.colors["app-bg"],
  flex: 1,
}));

export function SplashScreen() {
  return (
    <Container>
      <Screen>
        <Center>
          <Logo size="lg" />
        </Center>

        <AppInfo absolute />
      </Screen>
    </Container>
  );
}
