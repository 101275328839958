import type { IPutioAPIClientError } from "@putdotio/api-client";
import type PutioAPIClient from "@putdotio/api-client";

import { useAPI } from "@putdotio/core";
import { useQuery } from "@tanstack/react-query";

const createAuthCode = (api: PutioAPIClient) => async (clientName: string) => {
  const { data } = await api.Auth.GetCode(
    String(api.options.clientID),
    clientName,
  );

  return data.code;
};

export const useCreateAuthCode = (clientName: string) => {
  const api = useAPI();

  const result = useQuery<string, IPutioAPIClientError>({
    queryFn: () => createAuthCode(api)(clientName),
    queryKey: ["create-auth-code", clientName],
  });

  return result;
};
