import {
  blackA,
  gray,
  grayDark,
  green,
  greenDark,
  lime,
  red,
  redDark,
  whiteA,
  yellow,
  yellowDark,
} from "@radix-ui/colors";
import { generateSpectrumFromRadixColor } from "./radix-utils";

export const LIGHT_UI_COLORS = {
  ...generateSpectrumFromRadixColor(gray),
  ...generateSpectrumFromRadixColor(green, "green"),
  ...generateSpectrumFromRadixColor(red, "red"),
  ...generateSpectrumFromRadixColor(yellow, "yellow", {
    solid: "#FDCE45",
    "solid-hover": "#FDD868",
  }),

  "html-bg": gray.gray2,
  "app-bg": "white",

  "list-item-bg": "transparent",
  "list-item-bg-active": gray.gray2,
  "list-item-bg-hover": gray.gray2,
  "list-item-border": gray.gray5,

  "nav-bg": "white",
  "nav-item-bg": "transparent",
  "nav-item-bg-hover": gray.gray3,
  "nav-item-bg-active": gray.gray3,

  "segmented-control-bg": gray.gray3,
  "segmented-control-bg-active": "white",

  "transfer-list-item-completing-bg": lime.lime3,
  "transfer-list-item-downloading-bg": lime.lime3,
  "transfer-list-item-finished-bg": gray.gray2,
  "transfer-list-item-passive-bg": gray.gray1,

  "hi-contrast": gray.gray12,
  "lo-contrast": gray.gray1,

  "overlay-inline": blackA.blackA3,
  "overlay-full": blackA.blackA9,

  shadow: gray.gray8,
  transparent: "transparent",
};

export type Colors = typeof LIGHT_UI_COLORS;
export type Color = keyof Colors;

export const DARK_UI_COLORS: Colors = {
  ...generateSpectrumFromRadixColor(grayDark),
  ...generateSpectrumFromRadixColor(greenDark, "green"),
  ...generateSpectrumFromRadixColor(redDark, "red"),
  ...generateSpectrumFromRadixColor(yellowDark, "yellow", {
    solid: "#FDCE45",
    "solid-hover": "#FCBE03",
  }),

  "html-bg": "black",
  "app-bg": grayDark.gray1,

  "list-item-bg": "transparent",
  "list-item-bg-hover": grayDark.gray2,
  "list-item-bg-active": grayDark.gray2,
  "list-item-border": grayDark.gray5,

  "nav-bg": grayDark.gray1,
  "nav-item-bg": "transparent",
  "nav-item-bg-active": grayDark.gray3,
  "nav-item-bg-hover": grayDark.gray3,

  "segmented-control-bg": grayDark.gray1,
  "segmented-control-bg-active": grayDark.gray3,

  "transfer-list-item-completing-bg": grayDark.gray3,
  "transfer-list-item-downloading-bg": grayDark.gray3,
  "transfer-list-item-finished-bg": grayDark.gray2,
  "transfer-list-item-passive-bg": grayDark.gray2,

  "hi-contrast": grayDark.gray12,
  "lo-contrast": grayDark.gray1,

  "overlay-inline": blackA.blackA9,
  "overlay-full": blackA.blackA11,
  shadow: "black",
  transparent: "transparent",
};
