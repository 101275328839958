import { styled } from '@/lib/theme';

import { Label } from './ui/text';

type Props = {
  title: string;
};

const Container = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.appBg,
  paddingBottom: theme.spacing.sm,
  paddingLeft: theme.screen.header.paddingLeft,
  paddingRight: theme.screen.header.paddingRight,
}));

const Text = styled(Label)(({ theme }) => ({
  color: theme.colors.textContrast,
  fontSize: theme.text.caption.fontSize,
}));

export function ListSectionHeader({ title }: Props) {
  return (
    <Container>
      <Text>{title}</Text>
    </Container>
  );
}
