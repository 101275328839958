import type { ListItemProps } from "@/components";

import { useNavigation } from "@/lib/navigation";
import { getAppInfo, getDeviceInfo } from "@/lib/utils";

export const useAppandDeviceInfoListItems = (): ListItemProps[] => {
  const navigation = useNavigation();
  const app = getAppInfo();
  const device = getDeviceInfo();

  return [
    {
      description: app.release,
      icon: "drafting-compass",
      title: "App",
    },

    {
      description: `${device.name} - ${device.model}`,
      icon: "tv-2",
      title: "Device",
    },

    {
      description: device.os,
      icon: "component",
      title: "Operating system",
    },

    {
      accessory: { type: "link" },
      description: "",
      icon: "bug",
      onPress: () => navigation.push("diagnostics"),
      title: "Diagnostics",
    },
  ];
};
