import { useAPI } from "@putdotio/core";
import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useCallback } from "react";

import { authStateAtom } from "../atoms/auth";

export const useLogout = () => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const [, setAuthState] = useAtom(authStateAtom);

  /**
   * A function that will revoke the token, clear the PutioAPI token, set the auth state to unauthenticated, and clear the query cache.
   */
  const logout = useCallback(async () => {
    await api.Auth.Logout();
    api.clearToken();
    queryClient.clear();
    setAuthState({ status: "unauthenticated" });
  }, [api, queryClient, setAuthState]);

  return logout;
};
