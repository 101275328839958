import type { ISearchResponse } from '@putdotio/api-client';
import type { UseQueryOptions } from '@tanstack/react-query';

import { useAPI } from '@putdotio/core';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from 'use-debounce';

export const useFileSearch = (
  query: string,
  options: UseQueryOptions<ISearchResponse> = {},
) => {
  const api = useAPI();
  const [value] = useDebounce(query, 300);

  const result = useQuery<ISearchResponse>({
    enabled: !!value,

    queryFn: async () => {
      const { data } = await api.Files.Search(value, {
        fileType: ['FOLDER', 'VIDEO'],
        perPage: 50,
      });

      return data;
    },
    queryKey: ['file-search', value],
    ...options,
  });

  return result;
};
