import type { ListItemProps } from "@/components";

import { useNavigation } from "@/lib/navigation";
import { useAccountInfo, useAccountSettings } from "@putdotio/features";
import { toHumanFileSize } from "@putdotio/utilities";

export const useStorageListItems = (): ListItemProps[] => {
  const { data: accountInfo } = useAccountInfo();
  const [{ data: settings }, settingsMutation] = useAccountSettings();
  const navigation = useNavigation();

  return [
    {
      accessory: {
        type: "switch",
        value: settings.trash_enabled,
      },
      icon: { family: "lucide", name: "recycle" },
      onPress: () =>
        settingsMutation.mutateAsync({
          trash_enabled: !settings.trash_enabled,
        }),
      title: "Move deleted files to trash",
    },
    settings.trash_enabled
      ? {
          accessory: { type: "link" },
          description: toHumanFileSize(accountInfo.trash_size),
          icon: {
            family: "lucide",
            name: accountInfo.trash_size ? "trash-2" : "trash",
          },
          onPress: () => navigation.push("trash"),
          title: "Manage your trash",
        }
      : null,
  ].filter(Boolean) as ListItemProps[];
};
