import type {
  APIErrorByErrorTypeLocalizer,
  ErrorLocalizer,
  LocalizedErrorRecoverySuggestionTypeAction,
} from '@putdotio/utilities';

import { translate } from '@putdotio/translations';

export const TOGGLE_WATCH_STATUS_FEATURE_DISABLED_ERROR: APIErrorByErrorTypeLocalizer =
  {
    error_type: 'FEATURE_DISABLED',
    kind: 'api_error_type',
    localize: () => ({
      message: translate(
        'files_update_watch_status_feature_disabled_error_message',
      ),
      recoverySuggestion: {
        description: translate(
          'files_update_watch_status_feature_disabled_error_recovery_instruction',
        ),
        type: 'instruction',
      },
    }),
  };

export const createFetchFilesErrorLocalizers = (
  params: {
    triggers?: {
      goBack?: LocalizedErrorRecoverySuggestionTypeAction['trigger'];
    };
  } = {},
): ErrorLocalizer<unknown>[] => [
  {
    error_type: 'FILE_LOST',
    kind: 'api_error_type',
    localize: () => ({
      message: translate('files_lost_title'),
      recoverySuggestion: {
        description: translate('files_lost_message'),
        ...(params.triggers?.goBack
          ? {
              trigger: params.triggers.goBack,
              type: 'action',
            }
          : {
              type: 'instruction',
            }),
      },
    }),
  },

  {
    kind: 'api_status_code',
    localize: () => ({
      message: translate('files_badrequest_title'),
      recoverySuggestion: {
        description: translate('files_badrequest_message'),
        ...(params.triggers?.goBack
          ? {
              trigger: params.triggers.goBack,
              type: 'action',
            }
          : {
              type: 'instruction',
            }),
      },
    }),
    status_code: 400,
  },

  {
    kind: 'api_status_code',
    localize: () => ({
      message: translate('files_notfound_title'),
      recoverySuggestion: {
        description: translate('files_notfound_message'),
        ...(params.triggers?.goBack
          ? {
              trigger: params.triggers.goBack,
              type: 'action',
            }
          : {
              type: 'instruction',
            }),
      },
    }),
    status_code: 404,
  },

  {
    kind: 'api_status_code',
    localize: () => ({
      message: translate('generic_error_503_title'),
      recoverySuggestion: {
        description: translate('generic_error_503_message'),
        ...(params.triggers?.goBack
          ? {
              trigger: params.triggers.goBack,
              type: 'action',
            }
          : {
              type: 'instruction',
            }),
      },
    }),
    status_code: 503,
  },
];
