import type { IFile } from "@putdotio/api-client";
import type PutioAPIClient from "@putdotio/api-client";
import type { UseQueryOptions } from "@tanstack/react-query";

import { useAPI } from "@putdotio/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import type { ConversionStatusData } from "./conversion-status";

import { getConversionStatusQueryKey } from "./conversion-status";
import { getStartFromQueryKey } from "./start-from";

type FileInfoParams = {
  id: IFile["id"];
} & Parameters<PutioAPIClient["Files"]["Query"]>["1"];

export type FileInfoResponse = {
  breadcrumbs: unknown[];
  cursor: null | string;
  files: IFile[];
  parent: IFile;
  total: number;
};

export const getFileInfoQueryKey = (id: IFile["id"]) => ["files", id];

export const useFileInfo = (
  params: FileInfoParams,
  options: UseQueryOptions<FileInfoResponse> = {},
) => {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useQuery<FileInfoResponse>({
    onSuccess: (data) => {
      if (typeof data.parent.start_from === "number") {
        queryClient.setQueryData<number>(
          getStartFromQueryKey(data.parent.id),
          data.parent.start_from,
        );
      }

      if (typeof data.parent.need_convert === "boolean") {
        const conversionStatus: ConversionStatusData = {
          id: data.parent.id,
          status: data.parent.need_convert
            ? "NEEDS_CONVERSION"
            : "CONVERSION_NOT_NEEDED",
        };

        queryClient.setQueryData<ConversionStatusData>(
          getConversionStatusQueryKey(data.parent.id),
          conversionStatus,
        );
      }

      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },

    queryFn: async () => {
      const { data } = await api.Files.Query(params.id, params);
      return data as FileInfoResponse;
    },

    queryKey: getFileInfoQueryKey(params.id),

    ...options,
  });
};
