import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { tokenStorage } from '../utils/token-storage';

export const AUTH_TOKEN_QUERY_KEY = ['auth', 'token'];
export const AUTH_TOKEN_STORAGE_KEY = '@putio:auth_token';

export const useAuthToken = () =>
  useQuery({
    queryFn: () => tokenStorage.getToken(AUTH_TOKEN_STORAGE_KEY),
    queryKey: AUTH_TOKEN_QUERY_KEY,
    staleTime: Infinity,
  });

export const useMutateAuthToken = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (token: null | string) => {
      queryClient.setQueryData(AUTH_TOKEN_QUERY_KEY, token);

      try {
        if (token) {
          await tokenStorage.setToken(AUTH_TOKEN_STORAGE_KEY, token);
        } else {
          await tokenStorage.removeToken(AUTH_TOKEN_STORAGE_KEY);
        }
      } catch (e) {
        console.log('@putio:auth - error setting token', e);
      }
    },
  });
};

export const usePrefetchAuthToken = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.prefetchQuery({
      queryFn: () => tokenStorage.getToken(AUTH_TOKEN_STORAGE_KEY),
      queryKey: AUTH_TOKEN_QUERY_KEY,
      staleTime: Infinity,
    });
};
