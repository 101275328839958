import type { Theme } from '@/lib/theme';

import { styled } from '@/lib/theme';

type Props = {
  x?: keyof Theme['spacing'];
  y?: keyof Theme['spacing'];
};

export const Spacer = styled.View<Props>`
  margin-top: ${(props) => (props.y ? props.theme.spacing[props.y] : `${0}px`)};
  margin-right: ${(props) =>
    props.x ? props.theme.spacing[props.x] : `${0}px`};
`;
