import type { NavScreenProps } from "@/lib/navigation/types";

import { LoadingScreen } from "@/components";
import { useAPI } from "@putdotio/core";
import { useTokenAuthenticator } from "@putdotio/features";
import { useLayoutEffect } from "react";

import { useMutateAuthToken } from "../queries/auth-token";

function AuthWithToken({
  navigation,
  route: {
    params: { token },
  },
}: NavScreenProps<"auth/with-token">) {
  const api = useAPI();
  const mutateAuthToken = useMutateAuthToken();
  const authState = useTokenAuthenticator(token, {
    onSuccess: (token) => {
      api.setToken(token);
      mutateAuthToken.mutate(token);
    },
  });

  useLayoutEffect(() => {
    if (authState.status === "error") {
      navigation.replace("auth/with-code");
    }
  }, [authState.status, navigation]);

  return <LoadingScreen title="Logging you in" />;
}

export default AuthWithToken;
