import type { IFile } from '@putdotio/api-client';

import { ErrorState, LoadingScreen } from '@/components';
import { localizeError } from '@/lib/errors';
import { useConversionStatus } from '@putdotio/features';

import { VideoConversionStatus } from '../components/video-conversion-status';

type Props = { file: IFile };

export const withConversionStatus = <P extends Props>(
  VideoPlayerComponent: React.ComponentType<P>,
): React.FC<P> =>
  function WithConversionStatus(props) {
    const conversionStatusQuery = useConversionStatus(props.file.id, {
      enabled: props.file.need_convert,
    });

    switch (conversionStatusQuery.status) {
      case 'loading':
      default:
        return <LoadingScreen />;

      case 'error':
        return (
          <ErrorState error={localizeError(conversionStatusQuery.error)} />
        );

      case 'success':
        switch (conversionStatusQuery.data.status) {
          case 'COMPLETED':
          case 'CONVERSION_NOT_NEEDED':
          case 'NOT_AVAILABLE':
            return <VideoPlayerComponent {...props} />;

          default:
            return (
              <VideoConversionStatus
                data={conversionStatusQuery.data}
                posterURL={props.file.screenshot}
                title={props.file.name}
              />
            );
        }
    }
  };
