import { useMemo } from 'react';
import { Platform } from 'react-native';

import type { Config } from '../queries/config';

import { useConfigValue } from '../queries/config';

export const usePlaybackTypeConfig = (): Config['playbackType'] => {
  const isTVOS = Platform.OS === 'ios';

  const [playbackType] = useConfigValue('playbackType', {
    enabled: !isTVOS,
  });

  return useMemo(() => {
    if (isTVOS) {
      return 'hls';
    }

    return playbackType;
  }, [isTVOS, playbackType]);
};
