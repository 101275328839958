import { translate } from '@putdotio/translations';
import { toHumanFileSize } from '@putdotio/utilities';

import { useAccountInfo } from './account-info';

type DiskUsageDisplayType = 'available' | 'used';

type DiskUsageDisplayFormat = 'detailed' | 'simple';

type DiskUsageParams = {
  displayFormat: DiskUsageDisplayFormat;
  displayType: DiskUsageDisplayType;
};

const getFormatterOptions = (
  size: number,
  displayFormat: DiskUsageDisplayFormat,
) => {
  if (displayFormat === 'detailed') {
    return {
      decimalPlaces: 0,
      unit: size >= 1099511627776 ? 'GB' : 'MB',
    } as const;
  }

  return {
    decimalPlaces: 0,
  } as const;
};

export const useDiskUsage = (
  params: DiskUsageParams = {
    displayFormat: 'simple',
    displayType: 'available',
  },
) => {
  const {
    data: { disk },
  } = useAccountInfo();
  const { avail = 0, size = 0, used = 0 } = disk;
  const { displayFormat, displayType } = params;

  const formatterOptions = getFormatterOptions(size, displayFormat);

  if (displayType === 'available') {
    return {
      disk,
      label: translate(
        'usage_label_optimistic',
        toHumanFileSize(avail, formatterOptions),
        toHumanFileSize(size, formatterOptions),
      ),
    } as const;
  }

  return {
    disk,
    label: translate(
      'usage_label',
      toHumanFileSize(used, formatterOptions),
      toHumanFileSize(size, formatterOptions),
    ),
  } as const;
};
