import type { NavScreenProps } from '@/lib/navigation/types';

import { useLayoutEffect } from 'react';

import { useAuthToken } from '../queries/auth-token';

function AuthScreen({ navigation }: NavScreenProps<'auth'>) {
  const authTokenQuery = useAuthToken();

  useLayoutEffect(() => {
    if (authTokenQuery.data) {
      navigation.replace('auth/with-token', { token: authTokenQuery.data });
    } else {
      navigation.replace('auth/with-code');
    }
  }, [authTokenQuery.data, navigation]);

  return null;
}

export default AuthScreen;
