import { getAppInfo } from '@/lib/utils';
import {
  usePrefetchRemoteConfig as libUsePrefetchRemoteConfig,
  useRemoteConfig as libUseRemoteConfig,
} from '@putdotio/features';

type BufferConfig = {
  bufferForPlaybackAfterRebufferMs: number;
  bufferForPlaybackMs: number;
  maxBufferMs: number;
  minBufferMs: number;
};

type RemoteConfig = {
  bufferSize: {
    high: BufferConfig;
    low: BufferConfig;
    medium: BufferConfig;
  };

  updateNotifierEnabled: boolean;
};

const DEFAULT_REMOTE_CONFIG: RemoteConfig = {
  bufferSize: {
    high: {
      bufferForPlaybackAfterRebufferMs: 5000,
      bufferForPlaybackMs: 2500,
      maxBufferMs: 50000,
      minBufferMs: 15000,
    },
    low: {
      bufferForPlaybackAfterRebufferMs: 1500,
      bufferForPlaybackMs: 750,
      maxBufferMs: 15000,
      minBufferMs: 3000,
    },
    medium: {
      bufferForPlaybackAfterRebufferMs: 3000,
      bufferForPlaybackMs: 1500,
      maxBufferMs: 30000,
      minBufferMs: 8000,
    },
  },

  updateNotifierEnabled: false,
};

export const useRemoteConfig = () => {
  const app = getAppInfo();

  return libUseRemoteConfig<RemoteConfig>(app.id, {
    initialData: DEFAULT_REMOTE_CONFIG,
    initialDataUpdatedAt: 0,
    staleTime: 60 * 1000 * 15, // 15 minutes
  });
};

export const usePrefetchRemoteConfig = () => {
  const app = getAppInfo();
  const prefetchRemoteConfig = libUsePrefetchRemoteConfig();

  return () =>
    prefetchRemoteConfig(app.id, {
      initialData: DEFAULT_REMOTE_CONFIG,
      initialDataUpdatedAt: 0,
      staleTime: 60 * 1000 * 15, // 15 minutes
    });
};
