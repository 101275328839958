import { AppRegistry } from "react-native";

import appJSON from "./app.json";
import App from "./src";

AppRegistry.registerComponent(appJSON.name, () => App);

AppRegistry.runApplication(appJSON.name, {
  rootTag: document.getElementById("app"),
});
