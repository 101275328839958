import type { VideoPlayerProps } from '@/components/video-player';
import type { IFile } from '@putdotio/api-client';

import { usePlaybackTypeConfig } from '@/features/config/lib';
import {
  useAccountSettings,
  useFileSubtitles,
  useFileURLProvider,
} from '@putdotio/features';
import { useMemo } from 'react';

export const useVideoPlayerSource = (
  file: IFile,
): VideoPlayerProps['source'] => {
  const playbackType = usePlaybackTypeConfig();
  const [{ data: settings }] = useAccountSettings();
  const fileURLProvider = useFileURLProvider();
  const subtitlesQuery = useFileSubtitles(file.id, {
    enabled: playbackType === 'mp4' && !settings.hide_subtitles,
    suspense: true,
  });

  return useMemo(() => {
    if (playbackType === 'mp4') {
      return {
        playbackType,
        textTracks: subtitlesQuery.data?.subtitles.map((subtitle) => ({
          language: subtitle.language_code,
          title: subtitle.name,
          type: 'application/x-subrip',
          uri: subtitle.url,
        })),
        video: {
          uri:
            fileURLProvider.getMP4StreamURL(file) ||
            fileURLProvider.getStreamURL(file),
        },
      } as VideoPlayerProps['source'];
    }

    return {
      playbackType,
      video: {
        type: 'm3u8',
        uri: fileURLProvider.getHLSStreamURL(file, {})!,
      },
    };
  }, [playbackType, file, subtitlesQuery.data?.subtitles, fileURLProvider]);
};
