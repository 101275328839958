import type { IPutioAPIClientError } from "@putdotio/api-client";
import type { UseQueryOptions } from "@tanstack/react-query";

import { useAPI } from "@putdotio/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import deepmerge from "deepmerge";

const CONFIG_QUERY_KEY = ["config"];

export type UseConfigOptions<C> = UseQueryOptions<C> & {
  initialData: C;
};

export const useConfig = <Config>(options: UseConfigOptions<Config>) => {
  const api = useAPI();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryFn: async () => {
      const previousConfig =
        queryClient.getQueryData<Config>(CONFIG_QUERY_KEY) || {};

      try {
        const {
          data: { config },
        } = await api.Config.Read<Config>();

        return deepmerge.all<Config>([previousConfig, config]);
      } catch (err) {
        return previousConfig as Config;
      }
    },
    queryKey: CONFIG_QUERY_KEY,
    ...options,
  });

  return query;
};

export const useConfigValue = <Config, Key extends keyof Config>(
  key: Key,
  options: UseConfigOptions<Config>,
) => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const userConfig = useConfig<Config>(options);

  const mutation = useMutation<
    unknown,
    IPutioAPIClientError,
    Config[Key],
    { previousConfig: Config }
  >(
    async (newValue) => {
      await api.Config.SetKey(key, newValue);
      return newValue;
    },
    {
      onError: (_err, _variables, context) => {
        if (context?.previousConfig) {
          queryClient.setQueryData<Config>(
            CONFIG_QUERY_KEY,
            context.previousConfig,
          );
        }
      },

      onMutate: async (newValue) => {
        await queryClient.cancelQueries(CONFIG_QUERY_KEY);

        const previousConfig =
          queryClient.getQueryData<Config>(CONFIG_QUERY_KEY);

        if (previousConfig) {
          queryClient.setQueryData<Config>(CONFIG_QUERY_KEY, {
            ...previousConfig,
            [key]: newValue,
          });

          return { previousConfig };
        }
      },
    },
  );

  return [userConfig.data[key], mutation.mutate] as const;
};
