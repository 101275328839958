import type { ListItemProps } from '@/components';
import type { IFile } from '@putdotio/api-client';
import type { FlashListProps } from '@shopify/flash-list';

import { ListItem } from '@/components';
import { useTheme } from '@/lib/theme';
import { toHumanFileSize } from '@putdotio/utilities';
import { FlashList } from '@shopify/flash-list';
import { memo, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { noop } from 'remeda';

type FileListItemProps = {
  file: IFile;
  hasTVPreferredFocus: boolean;
  onLongPress?: (file: IFile) => void;
  onPress?: (file: IFile) => void;
};

function FileListItem({
  file,
  hasTVPreferredFocus,
  onLongPress = noop,
  onPress = noop,
}: FileListItemProps) {
  const icon = useMemo<ListItemProps['icon']>(() => {
    switch (file.file_type) {
      case 'FOLDER':
        return { family: 'lucide', name: 'folder-closed' };

      case 'VIDEO':
        return { family: 'lucide', name: 'film' };

      default:
        return { family: 'lucide', name: 'file' };
    }
  }, [file.file_type]);

  const accessory = useMemo<ListItemProps['accessory'] | undefined>(() => {
    if (file.deleted_at) {
      return;
    }

    switch (file.file_type) {
      case 'FOLDER':
        return { type: 'link' };

      case 'VIDEO':
        if (file.start_from > 0) {
          return {
            props: { family: 'lucide', name: 'eye' },
            type: 'icon',
          };
        }

        break;

      default:
        break;
    }
  }, [file.deleted_at, file.file_type, file.start_from]);

  const handlePress = useCallback(() => onPress(file), [onPress, file]);

  const handleLongPress = useCallback(
    () => onLongPress(file),
    [onLongPress, file],
  );

  return (
    <ListItem
      accessory={accessory}
      description={toHumanFileSize(file.size)}
      hasTVPreferredFocus={hasTVPreferredFocus}
      icon={icon}
      onLongPress={handleLongPress}
      onPress={handlePress}
      title={file.name}
    />
  );
}

const MemoizedFileListItem = memo(FileListItem, isEqual);

export type FileListProps = {
  files: IFile[];
  hasTVPreferredFocus?: FileListItemProps['hasTVPreferredFocus'];
  onLongPress?: FileListItemProps['onLongPress'];
  onPress?: FileListItemProps['onPress'];
};

const estimatedItemSize = 152;

export function FileList({
  files,
  hasTVPreferredFocus,
  onLongPress,
  onPress,
}: FileListProps) {
  const theme = useTheme();

  const renderItem = useCallback<
    NonNullable<FlashListProps<IFile>['renderItem']>
  >(
    ({ extraData, item }) => (
      <MemoizedFileListItem
        file={item}
        hasTVPreferredFocus={
          extraData.firstItemId === item.id && extraData.hasTVPreferredFocus
        }
        onLongPress={onLongPress}
        onPress={onPress}
      />
    ),
    [onPress, onLongPress],
  );

  return (
    <FlashList
      data={files}
      estimatedItemSize={estimatedItemSize}
      estimatedListSize={{
        height: theme.list.estimatedHeight,
        width: theme.list.estimatedHeight,
      }}
      extraData={{ firstItemId: files[0]?.id, hasTVPreferredFocus }}
      persistentScrollbar
      renderItem={renderItem}
    />
  );
}
