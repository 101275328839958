import type { NavScreenProps } from "@/lib/navigation/types";
import type { TVHistoryEvent } from "@putdotio/features";

import {
  EmptyState,
  ListItem,
  ListSectionFooter,
  ListSectionHeader,
  Screen,
} from "@/components";
import { AlertDialog } from "@/components/modal/alert-dialog";
import { Button } from "@/components/ui/button";
import { useModal } from "@/lib/contexts/modal";
import { withSuspense } from "@/lib/hocs/with-suspense";
import { SuspenseError } from "@putdotio/core";
import {
  getFilteredEventsForTV,
  getLabelForGroup,
  groupEventsByDate,
  useHistoryEvents,
} from "@putdotio/features";
import { translate } from "@putdotio/translations";
import { toHumanFileSize, toTimeAgo } from "@putdotio/utilities";
import { memo, useCallback, useMemo } from "react";
import { SectionList } from "react-native";

function HistoryListItem({
  event,
  hasTVPreferredFocus,
  onPress,
}: {
  event: TVHistoryEvent;
  hasTVPreferredFocus: boolean;
  onPress: (event: TVHistoryEvent) => void;
}) {
  const handlePress = useCallback(() => onPress(event), [event, onPress]);

  return (
    <ListItem
      description={[
        toTimeAgo(event.created_at),
        event.type === "file_shared"
          ? translate("file_shared_by", event.sharing_user_name)
          : toHumanFileSize(event.transfer_size),
      ].join("  |  ")}
      hasTVPreferredFocus={hasTVPreferredFocus}
      icon={event.type === "file_shared" ? "file-symlink" : "file-input"}
      onPress={handlePress}
      title={
        event.type === "file_shared" ? event.file_name : event.transfer_name
      }
    />
  );
}

const MemoizedHistoryListItem = memo(
  HistoryListItem,
  (prev, next) => prev.event.id === next.event.id,
);

function HistoryScreen({ navigation }: NavScreenProps<"history">) {
  const { showModal } = useModal();

  const [eventsQuery, { clearEventsMutation }] = useHistoryEvents({
    select: getFilteredEventsForTV,
    suspense: true,
  });

  if (!eventsQuery.data) {
    throw new SuspenseError();
  }

  const handlePress = useCallback(
    (event: TVHistoryEvent) => {
      navigation.navigate("files", { id: event.file_id });
    },
    [navigation],
  );

  const handleClear = useCallback(() => {
    showModal(({ hide }) => (
      <AlertDialog
        confirmLabel="Clear"
        onCancel={hide}
        onConfirm={clearEventsMutation.mutateAsync}
        title="Are you sure you want to clear your history?"
      />
    ));
  }, [clearEventsMutation.mutateAsync, showModal]);

  const sections = useMemo(() => {
    const eventGroups = groupEventsByDate(eventsQuery.data as TVHistoryEvent[]);

    return eventGroups
      .filter((group) => group.events.length > 0)
      .map(({ events, group }) => ({
        data: events,
        title: getLabelForGroup(group),
      }));
  }, [eventsQuery.data]);

  const renderSectionHeader = useCallback(
    ({ section: { title } }: { section: { title: string } }) => (
      <ListSectionHeader title={title} />
    ),
    [],
  );

  const renderItem = useCallback(
    ({ item }: { item: TVHistoryEvent }) => (
      <MemoizedHistoryListItem
        event={item}
        hasTVPreferredFocus={item.id === eventsQuery.data[0].id}
        onPress={handlePress}
      />
    ),
    [handlePress, eventsQuery.data],
  );

  if (eventsQuery.data.length === 0) {
    return (
      <Screen title={translate("menu_dashboard")}>
        <EmptyState
          icon="history"
          message={translate("dashboard_empty_message")}
          title={translate("dashboard_empty_title")}
        />
      </Screen>
    );
  }

  return (
    <Screen
      actions={
        <Button icon="delete" onPress={handleClear} size="sm">
          Clear
        </Button>
      }
      title={translate("menu_dashboard")}
    >
      <SectionList
        keyExtractor={(item, index) => `${index}-${item.id}`}
        persistentScrollbar
        renderItem={renderItem}
        renderSectionFooter={() => <ListSectionFooter />}
        renderSectionHeader={renderSectionHeader}
        sections={sections}
      />
    </Screen>
  );
}

export default withSuspense(HistoryScreen);
