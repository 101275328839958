import type { IFile } from '@putdotio/api-client';

import { VideoPlayer } from '@/components/video-player';
import { useBufferConfig } from '@/features/config/lib';
import { useNavigation } from '@/lib/navigation';
import { useCallback } from 'react';

import { withConversionStatus } from '../hocs/with-conversion-status';
import { useStartFromHandler } from '../hooks/use-start-from-handler';
import { useVideoPlayerSource } from '../hooks/use-video-player-source';

type Props = {
  file: IFile;
};

function Video({ file }: Props) {
  const navigation = useNavigation();
  const source = useVideoPlayerSource(file);
  const bufferConfig = useBufferConfig();
  const [startFrom, setStartFrom] = useStartFromHandler(file.id);
  const handleEnd = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <VideoPlayer
      bufferConfig={bufferConfig}
      duration={file.media_info.format.duration}
      id={file.id}
      onEnd={handleEnd}
      onTimeUpdate={setStartFrom}
      posterURL={file.screenshot}
      source={source}
      startTime={startFrom}
      title={file.name}
    />
  );
}

export default withConversionStatus(Video);
