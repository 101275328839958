import { XStack } from '@/components/ui/stack';
import { Label } from '@/components/ui/text';
import { styled } from '@/lib/theme';

const CharContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.hiContrast,
  borderRadius: theme.radii.default,
  height: theme.text.label.fontSize * 2,
  justifyContent: 'center',
  width: theme.text.label.fontSize * 2,
}));

export function ActivationCode({ code }: { code: string }) {
  return (
    <XStack spacing="sm">
      {code.split('').map((char, index) => (
        /* eslint-disable-next-line react/no-array-index-key -- nothing else to do? */
        <CharContainer key={`${char}-${index}`}>
          <Label color="loContrast">{char}</Label>
        </CharContainer>
      ))}
    </XStack>
  );
}
