import type { Color } from "@putdotio/colors";

import { type Theme, useTheme } from "@/lib/theme";
import {
  AlertTriangle,
  AppWindow,
  ArrowDownWideNarrow,
  Bookmark,
  Box,
  Bug,
  Calendar,
  CalendarClock,
  Check,
  CheckCircle2,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Circle,
  Component,
  Delete,
  DraftingCompass,
  Ear,
  EarOff,
  Eye,
  EyeOff,
  File,
  FileInput,
  FileStack,
  FileSymlink,
  FileVideo,
  FileVideo2,
  Film,
  Folder,
  FolderClosed,
  FolderOpen,
  FolderSync,
  Gauge,
  Headphones,
  History,
  Info,
  ListChecks,
  LogOut,
  MessageSquareOff,
  MessageSquareText,
  MonitorDot,
  MonitorDown,
  MonitorPlay,
  MoveDown,
  MoveUp,
  Network,
  PauseCircle,
  Recycle,
  RefreshCcw,
  Search,
  Settings,
  Subtitles,
  Trash,
  Trash2,
  Tv2,
  Type,
  User,
  XCircle,
} from "lucide-react-native";

const ICONS = {
  "alert-triangle": AlertTriangle,
  "app-window": AppWindow,
  "arrow-down-wide-narrow": ArrowDownWideNarrow,
  bookmark: Bookmark,
  box: Box,
  bug: Bug,
  calendar: Calendar,
  "calendar-clock": CalendarClock,
  check: Check,
  "check-circle-2": CheckCircle2,
  "chevron-down": ChevronDown,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  circle: Circle,
  component: Component,
  delete: Delete,
  "drafting-compass": DraftingCompass,
  ear: Ear,
  "ear-off": EarOff,
  eye: Eye,
  "eye-off": EyeOff,
  file: File,
  "file-input": FileInput,
  "file-stack": FileStack,
  "file-symlink": FileSymlink,
  "file-video": FileVideo,
  "file-video-2": FileVideo2,
  film: Film,
  folder: Folder,
  "folder-closed": FolderClosed,
  "folder-open": FolderOpen,
  "folder-sync": FolderSync,
  gauge: Gauge,
  headphones: Headphones,
  history: History,
  info: Info,
  "list-checks": ListChecks,
  "log-out": LogOut,
  "message-square-off": MessageSquareOff,
  "message-square-text": MessageSquareText,
  "monitor-dot": MonitorDot,
  "monitor-down": MonitorDown,
  "monitor-play": MonitorPlay,
  "move-down": MoveDown,
  "move-up": MoveUp,
  network: Network,
  "pause-circle": PauseCircle,
  recycle: Recycle,
  "refresh-ccw": RefreshCcw,
  search: Search,
  settings: Settings,
  subtitles: Subtitles,
  trash: Trash,
  "trash-2": Trash2,
  "tv-2": Tv2,
  type: Type,
  user: User,
  "x-circle": XCircle,
} as const;

export type IconProps = {
  color?: Color;
  name: keyof typeof ICONS;
  size?: keyof Theme["text"] | number;
};

const DEFAULT_SIZE: NonNullable<IconProps["size"]> = "body";
const DEFAULT_COLOR: NonNullable<IconProps["color"]> = "text-secondary";

export function Icon(props: IconProps) {
  const theme = useTheme();

  const IconComponent = ICONS[props.name];

  const size =
    typeof props === "string" ? DEFAULT_SIZE : (props.size ?? DEFAULT_SIZE);

  const color =
    (typeof props === "string" ? DEFAULT_COLOR : props.color) ?? DEFAULT_COLOR;

  return (
    <IconComponent
      color={theme.colors[color]}
      size={typeof size === "number" ? size : theme.text[size].fontSize}
    />
  );
}
