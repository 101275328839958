import type PutioAPIClient from "@putdotio/api-client";

import React from "react";

type APIContextType = {
  getAPIClient: () => PutioAPIClient | undefined;
};

const APIContext = React.createContext<APIContextType>({
  getAPIClient: () => undefined,
});

const useAPIContext = () => {
  const context = React.useContext(APIContext);

  if (context === undefined) {
    throw new Error("useAPI must be used within a APIProvider");
  }

  return context;
};

export const useAPI = () => {
  const context = useAPIContext();
  const api = context.getAPIClient();

  if (api === undefined) {
    throw new Error(
      "useAPI must be used within a APIProvider with a client instance",
    );
  }

  return api;
};

export const APIProvider: React.FC<{
  children: React.ReactNode;
  getAPIClient: APIContextType["getAPIClient"];
}> = ({ children, getAPIClient }) => (
  <APIContext.Provider value={{ getAPIClient }}>{children}</APIContext.Provider>
);
