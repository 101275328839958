import { SelectModal } from './select-modal';

export function AlertDialog({
  cancelLabel = 'Cancel',
  confirmLabel = 'OK',
  onCancel,
  onConfirm,
  title = 'Alert',
}: {
  cancelLabel?: string;
  confirmLabel?: string;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
}) {
  return (
    <SelectModal
      onHide={onCancel}
      onSelect={(value) => {
        if (value === 0) {
          onConfirm();
        } else {
          onCancel();
        }
      }}
      options={[
        {
          destructive: true,
          label: confirmLabel,
          value: 0,
        },
        {
          label: cancelLabel,
          value: 1,
        },
      ]}
      title={title}
    />
  );
}
