import type { TVParallaxProperties } from "react-native";

import { DARK_UI_COLORS, LIGHT_UI_COLORS } from "@putdotio/colors";
import { Dimensions, Platform } from "react-native";
import { createGlobalStyle } from "styled-components";
import * as styledComponents from "styled-components/native";

type ThemeConfig = {
  colorMode: "dark" | "light";
};

export const createTheme = (config: ThemeConfig) => {
  const colors =
    config.colorMode === "light" ? LIGHT_UI_COLORS : DARK_UI_COLORS;

  const window = Dimensions.get("window");

  const tvParallaxProperties: TVParallaxProperties = {
    enabled: false,
  };

  const overscanSafeMargins = {
    bottom: window.height * 0.02,
    left: window.width * 0.04,
    right: window.width * 0.04,
    top: window.height * 0.02,
  };

  const spacing = {
    0: 0,
    lg: 64,
    md: 32,
    safe: {
      bottom: overscanSafeMargins.bottom,
      left: overscanSafeMargins.left,
      right: overscanSafeMargins.right,
      top: overscanSafeMargins.top,
    },
    sm: 16,
    xl: 128,
    xs: 8,
    xxl: 256,
    xxs: 4,
  };

  const theme = {
    colors,
    config,
    overscanSafeMargins,
    pressable: {
      tvParallaxProperties,
    },

    radii: {
      default: 12,
    },

    screen: {
      header: {
        paddingBottom: spacing.md,
        paddingLeft: spacing.safe.left,
        paddingRight: spacing.safe.right,
        paddingTop: spacing.md + spacing.safe.top,
      },
      marginBottom: spacing.md + overscanSafeMargins.bottom,
      marginTop: spacing.md,
    },

    spacing,

    text: {
      body: {
        color: colors.text,
        fontFamily: "GTAmerica-Rg",
        fontSize: 36,
      },
      caption: {
        color: colors.text,
        fontFamily: "GTAmerica-Rg",
        fontSize: 32,
      },
      heading: {
        color: colors.text,
        fontFamily: "GTAmerica-Md",
        fontSize: 64,
      },
      label: {
        color: colors.text,
        fontFamily: "GTAmerica-Md",
        fontSize: 48,
      },
      smol: {
        color: colors.text,
        fontFamily: "GTAmerica-Rg",
        fontSize: 24,
      },
    },

    zIndices: {
      modal: 100,
      overlay: 300,
      toast: 200,
    },
  };

  const estimatedHeaderHeight =
    theme.screen.header.paddingTop +
    theme.screen.header.paddingBottom +
    theme.text.label.fontSize;

  const estimatedListViewHeight =
    window.height -
    estimatedHeaderHeight -
    theme.screen.marginTop -
    theme.screen.marginBottom;

  return {
    ...theme,
    list: {
      estimatedHeight: estimatedListViewHeight,
      estimatedWidth: window.width,
    },
  };
};

export type Theme = ReturnType<typeof createTheme>;

const { ThemeProvider, css, default: styled, useTheme } = styledComponents;

declare module "styled-components/native" {
  export interface DefaultTheme extends Theme {}
}

export { ThemeProvider, css, styled, useTheme };

export function GlobalStyle() {
  switch (Platform.OS) {
    case "web": {
      const WebGlobalStyle = createGlobalStyle({
        "#app": {
          display: "flex",
          height: "100%",
        },
        body: {
          height: "100%",
          margin: 0,
          overflow: "hidden",
        },
        html: {
          colorScheme: "dark",
          height: "100%",
        },
      });

      return <WebGlobalStyle />;
    }

    default:
      return null;
  }
}
