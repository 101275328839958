import { Sentry } from '@/lib/sentry';
import { useLogout as libUseLogout } from '@putdotio/features';

import { useMutateAuthToken } from '../queries/auth-token';

export const useLogout = () => {
  const logout = libUseLogout();
  const tokenMutation = useMutateAuthToken();

  return async () => {
    Sentry.setUser(null);
    await tokenMutation.mutateAsync(null);
    await logout();
  };
};
