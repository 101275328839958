import type { IconProps } from '@/components/ui/icon';
import type { GetPressableStyle } from '@/components/ui/pressable';
import type { Theme } from '@/lib/theme';
import type { StyleProp, ViewStyle } from 'react-native';

import { Icon } from '@/components/ui/icon';
import { Pressable } from '@/components/ui/pressable';
import { Spacer } from '@/components/ui/spacer';
import { XStack } from '@/components/ui/stack';
import { Caption } from '@/components/ui/text';
import { toast } from '@/lib/contexts/toast';
import { localizeError } from '@/lib/errors';
import { styled, useTheme } from '@/lib/theme';
import { LocalizedError } from '@putdotio/utilities';
import { useCallback, useMemo, useState } from 'react';
import { FlatList, Platform } from 'react-native';

import { Modal } from './modal';

export type SelectModalOption<T = number | string> = {
  destructive?: boolean;
  icon?: IconProps['name'];
  label: string;
  value: T;
};

type SelectModalProps<T extends number | string> = {
  onHide: () => void;
  onSelect: (value: T) => Promise<unknown> | unknown;
  options: SelectModalOption<T>[];
  title: string;
  value?: T;
};

const OptionIconContainer = styled.View(({ theme }) => ({
  marginRight: theme.spacing.sm,
}));

const OptionSelectedIconContainer = styled.View(() => ({
  marginLeft: 'auto',
}));

function OptionListItem<T extends number | string>(
  props: SelectModalOption<T> & {
    hasTVPreferredFocus: boolean;
    onPress: (value: T) => Promise<void> | void;
    selected: boolean;
  },
) {
  const {
    destructive,
    hasTVPreferredFocus,
    icon,
    label,
    onPress,
    selected,
    value,
  } = props;

  const theme = useTheme();
  const [focused, setFocused] = useState(false);

  const handleFocus = useCallback(() => setFocused(true), []);
  const handleBlur = useCallback(() => setFocused(false), []);
  const handlePress = useCallback(() => onPress(value), [value, onPress]);

  const getPressableStyle = useCallback<GetPressableStyle>(
    ({ focused }) => {
      const base = {
        backgroundColor: focused
          ? theme.colors.componentBgActive
          : 'transparent',
        borderRadius: theme.radii.default,
        paddingHorizontal: theme.spacing.sm,
        paddingVertical: theme.spacing.sm,
      };

      if (destructive) {
        return {
          ...base,
          backgroundColor: focused ? theme.colors.redSolid : 'transparent',
        };
      }

      return base;
    },
    [theme, destructive],
  );

  const textStyle = useMemo<{ color: keyof Theme['colors'] }>(() => {
    if (focused) {
      return {
        color: 'text',
      };
    }

    return {
      color: destructive ? 'redText' : 'text',
    };
  }, [focused, destructive]);

  const OptionIconText = useMemo(
    () =>
      styled(Caption)(({ theme }) => ({
        color: theme.colors[textStyle.color],
        flexGrow: 1,
        maxWidth: '90%',
      })),
    [textStyle],
  );

  return (
    <Pressable
      onBlur={handleBlur}
      onFocus={handleFocus}
      onPress={handlePress}
      style={getPressableStyle}
      {...theme.pressable}
      {...(Platform.OS === 'ios' ? {} : { hasTVPreferredFocus })}
    >
      <XStack>
        {icon ? (
          <OptionIconContainer>
            <Icon color={textStyle.color} name={icon} size="body" />
          </OptionIconContainer>
        ) : null}

        <OptionIconText ellipsizeMode="middle" numberOfLines={1}>
          {label}
        </OptionIconText>

        {selected ? (
          <OptionSelectedIconContainer>
            <Icon color="text" name="check" size="caption" />
          </OptionSelectedIconContainer>
        ) : null}
      </XStack>
    </Pressable>
  );
}

function ItemSeparatorComponent() {
  return <Spacer y="xs" />;
}

const selectModalStyle = {
  width: '25%',
};

export function SelectModal<T extends number | string>(
  props: SelectModalProps<T>,
) {
  const { onHide, onSelect, options, title, value } = props;
  const [loading, setLoading] = useState(false);

  const flatListStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({
      maxHeight: options.length * 80,
    }),
    [options.length],
  );

  return (
    <Modal loading={loading} style={selectModalStyle} title={title}>
      <FlatList
        ItemSeparatorComponent={ItemSeparatorComponent}
        data={options}
        keyExtractor={(item) => item.value.toString()}
        renderItem={({ index, item }) => (
          <OptionListItem
            {...item}
            hasTVPreferredFocus={
              typeof value !== 'undefined' ? item.value === value : index === 0
            }
            onPress={async (itemValue: T) => {
              setLoading(true);

              try {
                await onSelect(itemValue);
                setLoading(false);
                onHide();
              } catch (error) {
                setLoading(false);
                toast.show(
                  error instanceof LocalizedError
                    ? error
                    : localizeError(error),
                );
              }
            }}
            selected={item.value === value}
          />
        )}
        style={flatListStyle}
      />
    </Modal>
  );
}
