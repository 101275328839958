import { XStack } from "@/components/ui/stack";
import { Label } from "@/components/ui/text";
import { styled } from "@/lib/theme";
import { useMemo } from "react";

const CharContainer = styled.View(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors["hi-contrast"],
  borderRadius: theme.radii.default,
  height: theme.text.label.fontSize * 2,
  justifyContent: "center",
  width: theme.text.label.fontSize * 2,
}));

export function ActivationCode({ code }: { code: string }) {
  const chars = code.split("").map((char, index) => ({ char, key: index }));

  return (
    <XStack spacing="sm">
      {chars.map(({ char, key }) => (
        <CharContainer key={key}>
          <Label color="lo-contrast">{char}</Label>
        </CharContainer>
      ))}
    </XStack>
  );
}
