import type { VideoPlayerProps } from "@/components/video-player";
import type { NavScreenProps } from "@/lib/navigation/types";
import type { OnBandwidthUpdateData } from "react-native-video";

import { Screen, Smol } from "@/components";
import { VideoPlayer } from "@/components/video-player";
import { useBufferConfig, useConfigValue } from "@/features/config/lib";
import { styled } from "@/lib/theme";
import { useState } from "react";

const InfoView = styled.View(({ theme }) => ({
  backgroundColor: theme.colors["overlay-inline"],
  borderColor: theme.colors.line,
  borderRadius: theme.radii.default,
  borderWidth: 1,
  padding: theme.spacing.xs,
  position: "absolute",
  right: theme.spacing.xs,
  top: theme.spacing.xs,
  zIndex: 1,
}));

const InfoText = styled(Smol)({
  fontFamily: "monospace",
});

function DiagnosticsVideoPlayerScreen({
  route: {
    params: { stream },
  },
}: NavScreenProps<"diagnostics/video-player">) {
  const [bufferSize] = useConfigValue("bufferSize");
  const bufferConfig = useBufferConfig();
  const [bandwidth, setBandwidth] = useState<OnBandwidthUpdateData | null>();

  const info = {
    bandwidth,
    bufferConfig,
    bufferSize,
  };

  return (
    <Screen fullScreen>
      <InfoView>
        <InfoText>{JSON.stringify(info, null, 2)}</InfoText>
      </InfoView>

      <VideoPlayer
        bufferConfig={bufferConfig}
        id={stream.id}
        onBandwidthUpdate={setBandwidth}
        reportBandwidth
        source={stream.source as VideoPlayerProps["source"]}
        title={stream.title}
      />
    </Screen>
  );
}

export default DiagnosticsVideoPlayerScreen;
