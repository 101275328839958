import type { IconProps } from "./ui/icon";

import { Center } from "./ui/center";
import { Icon } from "./ui/icon";
import { Spacer } from "./ui/spacer";
import { YStack } from "./ui/stack";
import { Heading, Text } from "./ui/text";

export type EmptyStateProps = {
  icon: IconProps["name"];
  message: string;
  title: string;
};

export function EmptyState({ icon, message, title }: EmptyStateProps) {
  return (
    <Center paddingX="xxl">
      <YStack align="center" spacing="md">
        <Icon name={icon} size="heading" />
        <Heading>{title}</Heading>
        <Text color="text-secondary">{message}</Text>
        <Spacer y="lg" />
      </YStack>
    </Center>
  );
}
