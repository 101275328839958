import { ActivityIndicator } from "@/components/ui/activity-indicator";
import { styled } from "@/lib/theme";
import type { Color } from "@putdotio/colors";

type Props = {
  color?: Color;
  indicatorColor?: Color;
  size?: "large" | "small";
};

const Container = styled.View<{ color: Props["color"] }>(
  ({ color, theme }) => ({
    alignItems: "center",
    backgroundColor: theme.colors[color || "overlay-inline"],
    bottom: 0,
    flex: 1,
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: theme.zIndices.overlay,
  }),
);

export function LoadingOverlay({
  color = "overlay-inline",
  indicatorColor,
  size = "small",
}: Props) {
  return (
    <Container color={color}>
      <ActivityIndicator color={indicatorColor} size={size} />
    </Container>
  );
}
