import type { IFile } from "@putdotio/api-client";
import type { UseQueryOptions } from "@tanstack/react-query";

import { useAPI } from "@putdotio/core";
import { useQuery } from "@tanstack/react-query";

export type Subtitle = {
  format: string;
  key: string;
  language: string;
  language_code: string;
  name: string;
  source: "folder" | "mkv" | "opensubtitles" | "opensubtitles_rest";
  url: string;
};

export type IPutioSubtitlesResponse = {
  default: null | string;
  subtitles: Array<Subtitle>;
};

export const useFileSubtitles = (
  fileID: IFile["id"],
  options: UseQueryOptions<IPutioSubtitlesResponse> = {},
) => {
  const api = useAPI();

  const result = useQuery<IPutioSubtitlesResponse>({
    queryFn: async () => {
      const fetchSubtitles = api.File.Subtitles as unknown as (
        fileID: IFile["id"],
      ) => Promise<{ data: IPutioSubtitlesResponse }>;
      const response = await fetchSubtitles(fileID);
      return response.data as IPutioSubtitlesResponse;
    },
    queryKey: ["files", fileID, "subtitles"],
    ...options,
  });

  return result;
};
