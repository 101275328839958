import { styled } from '@/lib/theme';

import { Icon } from './ui/icon';
import { XStack, YStack } from './ui/stack';
import { Caption, Label } from './ui/text';

export type NotificationProps = {
  message?: string;
  title: string;
  type?: 'danger' | 'info' | 'success' | 'warning';
};

const NotificationContainer = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.componentBg,
  borderColor: theme.colors.line,
  borderRadius: theme.radii.default,
  borderWidth: 1,
  padding: theme.spacing.md,
}));

const NotificationTitle = styled(Label)(({ theme }) => ({
  fontSize: theme.text.caption.fontSize,
}));

const NotificationMessage = styled(Caption)(({ theme }) => ({
  color: theme.colors.textContrast,
}));

function NotificationIcon({
  type,
}: {
  type: NonNullable<NotificationProps['type']>;
}) {
  switch (type) {
    case 'danger':
      return <Icon color="redSolid" name="x-circle" />;

    case 'info':
    default:
      return <Icon color="solid" name="info" />;

    case 'success':
      return <Icon color="greenSolid" name="check-circle-2" />;

    case 'warning':
      return <Icon color="yellowSolid" name="alert-triangle" />;
  }
}

export function Notification({
  message,
  title,
  type = 'info',
}: NotificationProps) {
  return (
    <NotificationContainer>
      <XStack align="top" spacing="md">
        <NotificationIcon type={type} />

        {message ? (
          <YStack spacing="xs">
            <NotificationTitle>{title}</NotificationTitle>
            <NotificationMessage>{message}</NotificationMessage>
          </YStack>
        ) : (
          <NotificationTitle>{title}</NotificationTitle>
        )}
      </XStack>
    </NotificationContainer>
  );
}
