import type { FileConversionStatus, IFile } from "@putdotio/api-client";
import type { UseQueryOptions } from "@tanstack/react-query";

import { useAPI } from "@putdotio/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";

export type ConversionStatusData =
  | {
      id: IFile["id"];
      status: "CONVERSION_NOT_NEEDED";
    }
  | {
      id: IFile["id"];
      status: "NEEDS_CONVERSION";
    }
  | FileConversionStatus;

export const getConversionStatusQueryKey = (id: IFile["id"]) => [
  "files",
  id,
  "conversion_status",
];

export const useConvertMutation = () => {
  const api = useAPI();

  return useMutation({
    mutationFn: async (id: IFile["id"]) => {
      const {
        data: { mp4 },
      } = await api.File.ConvertToMp4(id);

      return mp4;
    },
  });
};

export const useConversionStatus = (
  id: IFile["id"],
  options: UseQueryOptions<ConversionStatusData> = {},
) => {
  const api = useAPI();

  const queryKey = getConversionStatusQueryKey(id);
  const queryClient = useQueryClient();

  const convertMutation = useConvertMutation();
  const convertMutationInitiatedRef = useRef(false);

  return useQuery<ConversionStatusData>({
    queryFn: async () => {
      const queryData =
        queryClient.getQueryData<ConversionStatusData>(queryKey);

      if (queryData?.status === "NEEDS_CONVERSION") {
        if (!convertMutationInitiatedRef.current) {
          convertMutationInitiatedRef.current = true;
          await convertMutation.mutateAsync(id);
        }

        return {
          id,
          status: "IN_QUEUE",
        };
      }

      const {
        data: { mp4 },
      } = await api.File.ConvertStatus(id);

      if (
        mp4.status === "NOT_AVAILABLE" &&
        queryData?.status === "CONVERSION_NOT_NEEDED"
      ) {
        return queryData;
      }

      return mp4;
    },

    queryKey,

    refetchInterval(data) {
      if (!data) {
        return false;
      }

      switch (data.status) {
        case "IN_QUEUE":
        case "CONVERTING":
        case "NEEDS_CONVERSION":
          return 3000;

        default:
          return false;
      }
    },

    ...options,
  });
};
