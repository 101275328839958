import { atom } from "jotai";

export type AuthState =
  | { accessToken: string; status: "authenticated" }
  | { error: unknown; status: "error" }
  | { status: "authenticating" }
  | { status: "unauthenticated" };

export const authStateAtom = atom<AuthState>({
  status: "unauthenticated",
});
