import type { ListItemProps } from "@/components";
import type { SectionListData } from "react-native";

import {
  ListItem,
  ListSectionFooter,
  ListSectionHeader,
  Screen,
  Spacer,
  Text,
  XStack,
} from "@/components";
import { Button } from "@/components/ui/button";
import { useLogout } from "@/features/auth/lib";
import { useEagerRefresh } from "@/lib/hooks";
import { styled } from "@/lib/theme";
import { useAccountInfo } from "@putdotio/features";
import { SectionList, View } from "react-native";

import { DiskUsage } from "../components/disk-usage";
import { useAppandDeviceInfoListItems } from "../hooks/use-app-and-device-info-list-items";
import { usePlaybackSettingsListItems } from "../hooks/use-playback-settings-list-items";
import { useStorageListItems } from "../hooks/use-storage-list-items";

const Avatar = styled.Image(({ theme }) => ({
  borderRadius: theme.radii.default,
  height: 120,
  width: 120,
}));

function AccountInfoCard() {
  const { data: accountInfo, refetch } = useAccountInfo();

  useEagerRefresh(refetch);

  return (
    <XStack align="bottom" spacing="md">
      <Avatar
        source={{ uri: accountInfo.avatar_url.replace("s=50", "s=200") }}
      />

      <View>
        <Text>{accountInfo.username}</Text>
        <Spacer y="sm" />

        <DiskUsage />
        <Spacer y="sm" />
      </View>
    </XStack>
  );
}

function Account() {
  const playbackSettingsItems = usePlaybackSettingsListItems();
  const storageItems = useStorageListItems();
  const appAndDeviceInfoItems = useAppandDeviceInfoListItems();
  const logout = useLogout();

  const sections: SectionListData<ListItemProps>[] = [
    {
      data: playbackSettingsItems,
      title: "Playback settings",
    },
    {
      data: storageItems,
      title: "Storage settings",
    },
    {
      data: appAndDeviceInfoItems,
      title: "App and device information",
    },
  ];

  return (
    <Screen
      header={
        <XStack
          align="bottom"
          justify="space-between"
          spacing="md"
          width="100%"
        >
          <AccountInfoCard />

          <Button icon="log-out" onPress={logout} size="sm">
            Sign out
          </Button>
        </XStack>
      }
    >
      <SectionList
        persistentScrollbar
        renderItem={({ item }) => <ListItem {...item} />}
        renderSectionFooter={() => <ListSectionFooter />}
        renderSectionHeader={({ section }) => (
          <ListSectionHeader title={section.title} />
        )}
        sections={sections}
        stickySectionHeadersEnabled={false}
      />
    </Screen>
  );
}

export default Account;
