import type { IPutioAPIClientErrorData } from '@putdotio/api-client';
import type {
  APIErrorByStatusCodeLocalizer,
  GenericErrorLocalizer,
  MatchConditionLocalizer,
} from '@putdotio/utilities';

import { Sentry } from '@/lib/sentry';
import { isPutioAPIError, isPutioAPIErrorResponse } from '@putdotio/api-client';
import {
  createNetworkErrorLocalizer,
  fileNotReachableErrorLocalizer,
  notFoundErrorLocalizer,
  serverErrorLocalizer,
  timeoutErrorLocalizer,
} from '@putdotio/core';
import { translate } from '@putdotio/translations';
import { createLocalizeError } from '@putdotio/utilities';

const authErrorLocalizer: APIErrorByStatusCodeLocalizer = {
  kind: 'api_status_code',
  localize: () => {
    return {
      message: translate('app_session_expired_title'),
      recoverySuggestion: {
        description: translate('app_session_expired_message'),
        type: 'instruction',
      },
    };
  },
  status_code: 401,
};

const ratelimitErrorLocalizer: APIErrorByStatusCodeLocalizer = {
  kind: 'api_status_code',
  localize: (error) => {
    const rateLimitId = error.response?.headers['x-ratelimit-id'] ?? null;

    return {
      message: translate('app_ratelimit_error_message'),
      recoverySuggestion: {
        description: `Please get in touch with us via support@put.io${
          rateLimitId ? ` and include the following ID:\n\n${rateLimitId}` : ''
        }`,
        type: 'instruction',
      },
    };
  },
  status_code: 429,
};

const genericAPIErrorLocalizer: MatchConditionLocalizer<unknown> = {
  kind: 'match_condition',
  localize: (error) => {
    const data = isPutioAPIError(error)
      ? error.data
      : (error as IPutioAPIClientErrorData);

    const traceId = Sentry.captureMessage(
      `UnlocalizedAPIError: ${data.status_code}.${data.error_type}`,
      {
        extra: { error },
      },
    );

    return {
      message: translate('generic_error_known_unknown_title'),
      recoverySuggestion: {
        description: translate(
          'generic_error_known_unknown_description',
          traceId,
        ),
        type: 'instruction',
      },
    };
  },
  match: (error) => isPutioAPIError(error) || isPutioAPIErrorResponse(error),
};

const genericErrorLocalizer: GenericErrorLocalizer = {
  kind: 'generic',
  localize: (error) => {
    const errorId = Sentry.captureException(error);

    return {
      message: translate('generic_error_unknown_unknown_title'),
      recoverySuggestion: {
        description: translate(
          'generic_error_unknown_unknown_description',
          errorId,
        ),
        type: 'instruction',
      },
    };
  },
};

export const localizeError = createLocalizeError([
  createNetworkErrorLocalizer(),
  authErrorLocalizer,
  ratelimitErrorLocalizer,
  timeoutErrorLocalizer,
  notFoundErrorLocalizer,
  fileNotReachableErrorLocalizer,
  serverErrorLocalizer,
  genericAPIErrorLocalizer,
  genericErrorLocalizer,
]);
