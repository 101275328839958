import { styled } from '@/lib/theme';

import LogoRetroDarkPNG from './logo-retro-dark.png';

type Size = 'lg' | 'sm';

const Image = styled.Image<{ size: Size }>(({ size, theme }) => ({
  height: size === 'sm' ? theme.text.label.fontSize : '25%',
}));

export function Logo({ size = 'sm' }: { size?: Size }) {
  return (
    <Image
      resizeMode="contain"
      size={size}
      source={LogoRetroDarkPNG}
      style={{
        aspectRatio: 921 / 240,
      }}
    />
  );
}
