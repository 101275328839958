import type {
  APIErrorByErrorTypeLocalizer,
  APIErrorByStatusCodeLocalizer,
  MatchConditionLocalizer,
} from '@putdotio/utilities';

import { isPutioAPIError } from '@putdotio/api-client';
import { translate } from '@putdotio/translations';
import { ExtendableError } from '@putdotio/utilities';

export class SuspenseError extends ExtendableError {}

export const createNetworkErrorLocalizer = ({
  onReload,
}: {
  onReload?: () => void;
} = {}): MatchConditionLocalizer<unknown> => ({
  kind: 'match_condition',
  localize: () => ({
    message: translate('generic_network_error_title'),
    recoverySuggestion: onReload
      ? {
          description: translate('generic_network_error_message'),
          trigger: {
            callback: onReload,
            label: translate('app_page_load_retry_label'),
          },
          type: 'action',
        }
      : {
          description: translate('generic_network_error_message'),
          type: 'instruction',
        },
  }),
  match: (e) => {
    if (isPutioAPIError(e)) {
      return e.data.status_code === 0;
    }

    if (e instanceof Error) {
      return (
        e.message.includes('Network Error') ||
        e.message.includes('Request aborted')
      );
    }

    return false;
  },
});

export const timeoutErrorLocalizer: MatchConditionLocalizer<unknown> = {
  kind: 'match_condition',
  localize: () => ({
    message: translate('timeout_error_title'),
    recoverySuggestion: {
      description: translate('timeout_error_message'),
      type: 'instruction',
    },
  }),
  match: (e) => {
    if (isPutioAPIError(e)) {
      return e.data.status_code === 408 || e.data.status_code === 504;
    }

    return e instanceof Error && e.message.includes('timeout of');
  },
};

export const notFoundErrorLocalizer: APIErrorByStatusCodeLocalizer = {
  kind: 'api_status_code',
  localize: () => ({
    message: 'Resource not found',
    recoverySuggestion: {
      description: 'The resource you are looking for is not available.',
      type: 'instruction',
    },
  }),
  status_code: 404,
};

export const serverErrorLocalizer: APIErrorByStatusCodeLocalizer = {
  kind: 'api_status_code',
  localize: () => ({
    message: 'Server error',
    recoverySuggestion: {
      description: 'Something went wrong on our side.',
      type: 'instruction',
    },
  }),
  status_code: 500,
};

export const fileNotReachableErrorLocalizer: APIErrorByErrorTypeLocalizer = {
  error_type: 'FILE_NOT_REACHABLE',
  kind: 'api_error_type',
  localize: () => ({
    message: translate('files_not_reachable_title'),
    recoverySuggestion: {
      description: translate('files_not_reachable_message'),
      type: 'instruction',
    },
  }),
};
