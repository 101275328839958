import {
  amber,
  amberDark,
  blackA,
  gray,
  grayDark,
  green,
  greenDark,
  lime,
  red,
  redDark,
  whiteA,
} from '@radix-ui/colors';

export const LIGHT_UI_COLORS = {
  /* GRAY  ------------------------------------------------------------------------------------- */
  appBg: 'white',
  appWrapperBg: gray.gray2, // applied to body when using non-fluid layout

  // interactive element border (e.g. button, input, select, dropdown)
  border: gray.gray7,
  borderHover: gray.gray8,

  // for components like card, select, dropdown, secondary button.
  componentBg: gray.gray3,
  componentBgActive: gray.gray5,
  componentBgHover: gray.gray4,
  greenBorder: green.green7,

  greenBorderHover: green.green8,
  /* GREEN ------------------------------------------------------------------------------------- */
  greenComponentBg: green.green3,
  greenComponentBgActive: green.green5,
  greenComponentBgHover: green.green4,

  greenLine: green.green6,
  greenSolid: green.green9,
  greenSolidHover: green.green10,

  greenText: green.green11,
  // tooltip
  hiContrast: gray.gray12,

  // non-interactive element border. use with appBg, navBg.
  line: gray.gray6,

  listItemBg: 'transparent',
  listItemBgActive: gray.gray2,

  listItemBgHover: gray.gray2,
  listItemBorder: gray.gray5,

  // dialog, popover, hovercard
  loContrast: 'white',

  navBg: 'white', // same as appBg

  navItemBg: 'transparent',

  navItemBgActive: gray.gray3,

  navItemBgHover: gray.gray3,
  /* ------------------------------------------------------------------------------------------- */

  // inline overlay (e.g. loading over list)
  overlay: whiteA.whiteA11,
  // modal backdrop, full-page overlay
  overlayFull: blackA.blackA8,
  redBorder: red.red11,

  /* RED --------------------------------------------------------------------------------------- */
  redComponentBg: red.red3,
  redComponentBgActive: red.red5,
  /* ------------------------------------------------------------------------------------------- */

  redComponentBgHover: red.red4,
  redSolid: red.red9,
  redSolidHover: red.red10,

  redText: red.red11,
  // segmented control: tabs, toggle group
  segmentedControlBg: gray.gray3,

  segmentedControlBgActive: 'white',
  shadow: gray.gray8,
  /* ------------------------------------------------------------------------------------------- */

  // purest shade of gray, for solid buttons.
  solid: gray.gray9,
  solidHover: gray.gray10,
  text: gray.gray12,

  textContrast: gray.gray11,
  transfer_list_item_completing_bg: lime.lime3,
  transfer_list_item_downloading_bg: lime.lime3,

  transfer_list_item_finished_bg: gray.gray2,
  transfer_list_item_passive_bg: gray.gray1,

  transparent: 'transparent',
  /* ------------------------------------------------------------------------------------------- */

  /* YELLOW ------------------------------------------------------------------------------------ */
  yellowComponentBg: amber.amber3,
  yellowComponentBgActive: amber.amber5,
  yellowComponentBgHover: amber.amber4,
  yellowSolid: '#FDCE45',

  yellowSolidHover: '#FDD868',
};

// types
export type Colors = typeof LIGHT_UI_COLORS;
export type ColorKey = keyof Colors;
export type Color = keyof Colors;

export const DARK_UI_COLORS: Colors = {
  /* GRAY  ------------------------------------------------------------------------------------- */
  appBg: grayDark.gray1,
  appWrapperBg: 'black',

  border: grayDark.gray7,
  borderHover: grayDark.gray8,

  componentBg: grayDark.gray3,
  componentBgActive: grayDark.gray5,
  componentBgHover: grayDark.gray4,
  greenBorder: greenDark.green7,

  greenBorderHover: greenDark.green8,
  /* GREEN ------------------------------------------------------------------------------------- */
  greenComponentBg: greenDark.green3,
  greenComponentBgActive: greenDark.green5,

  greenComponentBgHover: greenDark.green4,
  greenLine: greenDark.green6,

  greenSolid: greenDark.green9,
  greenSolidHover: greenDark.green10,
  greenText: greenDark.green11,
  hiContrast: grayDark.gray12,

  line: grayDark.gray6,

  listItemBg: 'transparent',
  listItemBgActive: grayDark.gray2,

  listItemBgHover: grayDark.gray2,
  listItemBorder: grayDark.gray5,

  loContrast: grayDark.gray1,
  navBg: grayDark.gray1, // same as appBg

  navItemBg: 'transparent',
  navItemBgActive: grayDark.gray3,

  navItemBgHover: grayDark.gray3,
  /* ------------------------------------------------------------------------------------------- */

  overlay: blackA.blackA9,
  overlayFull: blackA.blackA11,
  redBorder: redDark.red11,

  /* RED --------------------------------------------------------------------------------------- */
  redComponentBg: redDark.red3,
  redComponentBgActive: redDark.red5,
  /* ------------------------------------------------------------------------------------------- */

  redComponentBgHover: redDark.red4,
  redSolid: redDark.red9,
  redSolidHover: redDark.red10,

  redText: redDark.red11,
  segmentedControlBg: grayDark.gray1,

  segmentedControlBgActive: grayDark.gray3,
  shadow: 'black',
  /* ------------------------------------------------------------------------------------------- */

  solid: grayDark.gray9,
  solidHover: grayDark.gray10,
  text: grayDark.gray12,

  textContrast: grayDark.gray11,
  transfer_list_item_completing_bg: grayDark.gray3,
  transfer_list_item_downloading_bg: grayDark.gray3,

  transfer_list_item_finished_bg: grayDark.gray2,
  transfer_list_item_passive_bg: grayDark.gray2,

  transparent: 'transparent',
  /* ------------------------------------------------------------------------------------------- */

  /* YELLOW ------------------------------------------------------------------------------------ */
  yellowComponentBg: amberDark.amber3,
  yellowComponentBgActive: amberDark.amber5,
  yellowComponentBgHover: amberDark.amber4,
  yellowSolid: '#FDCE45',

  yellowSolidHover: '#FCBE03',
};
