import type { IFile } from '@putdotio/api-client';
import type { UseQueryOptions } from '@tanstack/react-query';

import { useAPI } from '@putdotio/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import throttle from 'lodash.throttle';
import { useEffect, useRef } from 'react';

const SET_START_FROM_INTERVAL = 15 * 1000;

export const getStartFromQueryKey = (id: IFile['id']) => [
  'files',
  id,
  'start_from',
];

export const useStartFrom = (
  id: IFile['id'],
  options: UseQueryOptions<number> = {},
) => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const queryKey = getStartFromQueryKey(id);

  const result = useQuery({
    initialData: 0,
    queryFn: async () => {
      try {
        const { data } = await api.File.GetStartFrom(id);
        return data.start_from;
      } catch {
        return 0;
      }
    },
    queryKey,
    staleTime: Infinity,
    ...options,
  });

  const mutation = useMutation({
    mutationFn: async (startFrom: number) => {
      startFrom = Math.round(startFrom);
      await queryClient.cancelQueries(queryKey);

      if (startFrom === queryClient.getQueryData<number>(queryKey)) {
        return startFrom;
      }

      queryClient.setQueryData<number>(queryKey, startFrom);

      try {
        await api.File.SetStartFrom(id, startFrom.toString());
        return startFrom;
      } catch {
        return startFrom;
      }
    },
  });

  const throttledMutation = useRef(
    throttle(mutation.mutate, SET_START_FROM_INTERVAL),
  );

  useEffect(() => {
    const _throttledMutation = throttledMutation.current;

    return () => {
      _throttledMutation.flush();
    };
  }, []);

  return [result.data, throttledMutation.current] as const;
};
