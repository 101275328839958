import type { ComponentType, PropsWithChildren } from "react";

import { generateShortId } from "@/lib/utils";
import { createContext, useCallback, useContext, useState } from "react";
import { Modal } from "react-native";

type ModalProps = {
  hide: () => void;
};

type ModalContextType = {
  showModal: (component: ComponentType<ModalProps>) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export function ModalProvider({ children }: PropsWithChildren) {
  const [modals, setModals] = useState<
    {
      component: ComponentType<ModalProps>;
      id: string;
    }[]
  >([]);

  const hideModal = useCallback((id: string) => {
    setModals((modals) => modals.filter((m) => m.id !== id));
  }, []);

  const showModal = useCallback<ModalContextType["showModal"]>(
    (component) => {
      const id = generateShortId();

      setModals((modals) => [...modals, { component, id }]);

      return {
        hide: () => hideModal(id),
      };
    },
    [hideModal],
  );

  return (
    <ModalContext.Provider value={{ showModal }}>
      {modals.map((modal) => {
        const hide = hideModal.bind(null, modal.id);

        return (
          <Modal
            animationType="none"
            key={modal.id}
            onRequestClose={hide}
            transparent
          >
            <modal.component hide={hide} />
          </Modal>
        );
      })}

      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};
