/**
 * These are the errors that we want to ignore in Sentry.
 * They are mostly caused by third-party scripts, browser extensions, etc.
 */
export const IGNORED_SENTRY_ERRORS = [
  'AbortError', // videojs - play/pause
  'BetterJsPop', // chrome extension
  'cannot redefine property', // no idea
  'fullscreen', // videojs
  'googleTag', // google tag manager
  'ibFindAllVideos', // android
  'Incorrect Invocation', // smart TV
  'Invalid target', // videojs
  'InvalidStateError', // videojs - picture-in-picture
  'Load Failed', // profitwell
  'NotSupportedError', // videojs - picture-in-picture
  'ntp is not defined', // chrome extension
  'ResizeObserver loop limit exceeded', // libraries that use ResizeObserver
  'timeout of', // http request timeout
  'vc_request_action', // some Tizen TV bullshit
  "Unexpected identifier 'Promise'", // no idea
];
