import type { PropsWithChildren } from 'react';

import { Center } from '@/components/ui/center';
import { YStack } from '@/components/ui/stack';
import { Label } from '@/components/ui/text';
import { styled } from '@/lib/theme';
import { Dimensions } from 'react-native';

export type PreVideoPromptProps = PropsWithChildren<{
  posterURL?: string;
  title: string;
}>;

export const POSTER_WIDTH = Dimensions.get('window').width * 0.4;

const Poster = styled.Image(({ theme }) => ({
  backgroundColor: theme.colors.componentBg,
  borderRadius: theme.radii.default,
  height: (POSTER_WIDTH * 9) / 16,
  width: POSTER_WIDTH,
}));

export function PreVideoPrompt({
  children,
  posterURL,
  title,
}: PreVideoPromptProps) {
  return (
    <Center>
      <YStack align="center" spacing="lg">
        <Label>{title}</Label>
        {posterURL ? <Poster source={{ uri: posterURL }} /> : null}
        {children}
      </YStack>
    </Center>
  );
}
