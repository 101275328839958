import { useConfigValue } from "@/features/config/lib";
import { useCallback } from "react";

export const updateSearchHistory = ({
  keyword,
  searchHistory,
}: {
  keyword: string;
  searchHistory: string[];
}) => {
  const MAX_ITEMS = 5;

  // Remove duplicates
  const filteredSearchHistory = searchHistory.filter(
    (item) => item !== keyword,
  );

  // Add new keyword to the beginning of the array
  const updatedSearchHistory = [keyword, ...filteredSearchHistory];

  // Limit the number of items to MAX_ITEMS
  const limitedSearchHistory =
    updatedSearchHistory.length > MAX_ITEMS
      ? updatedSearchHistory.slice(0, MAX_ITEMS)
      : updatedSearchHistory;

  return limitedSearchHistory;
};

export const useSearchHistory = () => useConfigValue("searchHistory");

export const useAddKeywordToSearchHistory = () => {
  const [searchHistory, setSearchHistory] = useSearchHistory();

  return useCallback(
    (keyword: string) =>
      setSearchHistory(
        updateSearchHistory({
          keyword,
          searchHistory,
        }),
      ),
    [searchHistory, setSearchHistory],
  );
};

export const useClearSearchHistory = () => {
  const [, setSearchHistory] = useSearchHistory();

  return useCallback(() => {
    setSearchHistory([]);
  }, [setSearchHistory]);
};
