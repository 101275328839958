import config from '@/config';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

export const generateShortId = (length = 1) =>
  [...Array(length)].map(() => (Math.random() * 36).toString(36)).join('');

export const getOAuthAppID = () => {
  switch (Platform.OS) {
    case 'android': {
      const brand = DeviceInfo.getBrand().toLocaleLowerCase();

      switch (brand) {
        case 'amazon':
          return config.oauthApps.fireTV;

        default:
          return config.oauthApps.androidTV;
      }
    }

    case 'ios': {
      return config.oauthApps.tvOS;
    }

    default:
      return config.oauthApps.web;
  }
};

export const getAppInfo = () => {
  const id = getOAuthAppID();

  return {
    id,
    ...config.app,
  };
};

export const getDeviceInfo = () => {
  const name = DeviceInfo.getDeviceNameSync();
  const os = `${DeviceInfo.getSystemName()} - v${DeviceInfo.getSystemVersion()}`;

  return {
    model: DeviceInfo.getModel(),
    name,
    os,
  };
};
