import { IGNORED_SENTRY_ERRORS } from '@putdotio/core';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

export const config = {
  get app() {
    const bundleId = DeviceInfo.getBundleId();
    const deviceVersion = DeviceInfo.getVersion();

    const name = bundleId === 'unknown' ? 'tv' : bundleId;
    const version = deviceVersion === 'unknown' ? '2.0.0' : deviceVersion;
    const buildNumber = parseInt(DeviceInfo.getBuildNumber(), 10) || 0;
    const release = `${name}@${version}+${buildNumber}`;

    return {
      buildNumber,
      name,
      release,
      version,
    };
  },

  oauthApps: {
    androidTV: 6221,
    fireTV: 6233,
    tvOS: 2951,
    web: 2836,
  },

  reactNative: {
    logBox: {
      ignoreLogs: [
        'to contain units',
        'NotVerifiedError',
        'BFFError',
        'Persistent storage is not supported on tvOS',
        'ProgressViewIOS',
        'appearanceChanged',
      ],
    },
  },

  get sentry() {
    const dsn =
      Platform.OS === 'android'
        ? 'https://dac5d922c5374812afc33ed032f78652@o804.ingest.sentry.io/4504922013106176'
        : 'https://4c14ad61e8594859b4f60efbd0b8b4ae@o804.ingest.sentry.io/239476';

    return {
      dsn,
      enableAppHangTracking: false,
      enabled: !__DEV__,
      ignoreErrors: IGNORED_SENTRY_ERRORS,
      normalizeDepth: 10,
      tracesSampleRate: 0.00001,
    };
  },
};

export default config;
