import type { SaveAccountSettingsPayload } from "@putdotio/api-client";

import { useAPI } from "@putdotio/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAccountInfo } from "./account-info";

export const useAccountSettings = () => {
  const api = useAPI();
  const queryKey = ["account-settings"];
  const queryClient = useQueryClient();
  const { data: accountInfo } = useAccountInfo();

  const query = useQuery({
    initialData: accountInfo.settings,
    queryFn: async () => {
      const { data } = await api.Account.Settings();
      return data.settings;
    },
    queryKey,
    staleTime: Number.POSITIVE_INFINITY,
  });

  const mutation = useMutation({
    mutationFn: (value: SaveAccountSettingsPayload) =>
      api.Account.SaveSettings(value),

    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return [query, mutation] as const;
};
