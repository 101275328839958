import { useAtom } from 'jotai';
import { useEffect } from 'react';

import type { Options } from '../queries/validate-token';

import { usePrefetchAccountInfo } from '../../account';
import { authStateAtom } from '../atoms/auth';
import { useValidateToken } from '../queries/validate-token';

export const useTokenAuthenticator = (token: string, options: Options = {}) => {
  const [authState, setAuthState] = useAtom(authStateAtom);
  const tokenValidationResult = useValidateToken(token, 'default', options);
  const prefetchAccountInfo = usePrefetchAccountInfo();

  useEffect(() => {
    const run = async () => {
      switch (tokenValidationResult.status) {
        case 'loading':
        default:
          return setAuthState({ status: 'authenticating' });

        case 'success': {
          try {
            await prefetchAccountInfo();
            return setAuthState({
              accessToken: token,
              status: 'authenticated',
            });
          } catch (error) {
            return setAuthState({ error, status: 'error' });
          }
        }

        case 'error':
          return setAuthState({
            error: tokenValidationResult.error,
            status: 'error',
          });
      }
    };

    run();
  }, [
    tokenValidationResult.status,
    tokenValidationResult.error,
    token,
    setAuthState,
    prefetchAccountInfo,
  ]);

  return authState;
};
