import type { NavScreenProps } from '@/lib/navigation/types';

import { EmptyState, ErrorState, Screen } from '@/components';
import { localizeError } from '@/lib/errors';
import { withSuspense } from '@/lib/hocs/with-suspense';
import { useRefreshOnFocus } from '@/lib/hooks';
import { SuspenseError } from '@putdotio/core';
import {
  createFetchFilesErrorLocalizers,
  useFileInfo,
} from '@putdotio/features';
import { translate } from '@putdotio/translations';
import { LocalizedError } from '@putdotio/utilities';
import { useCallback, useMemo } from 'react';

import type { FileListProps } from '../components/file-list';

import { FileActions } from '../components/file-actions';
import { FileList } from '../components/file-list';
import VideoFile from '../components/video';
import { useLongPressFileActions } from '../hooks/use-long-press-file-actions';

function FileScreen({
  navigation,
  route: { params },
}: NavScreenProps<'files'>) {
  const query = useFileInfo(
    {
      id: params.id,
      mediaInfoParent: true,
      mp4StatusParent: true,
      mp4StreamUrlParent: true,
      streamUrlParent: true,
    },
    {
      suspense: true,
    },
  );

  if (!query.data) {
    throw new SuspenseError();
  }

  useRefreshOnFocus(query.refetch);

  const handlePress = useCallback<NonNullable<FileListProps['onPress']>>(
    (file) => {
      navigation.push('files', { id: file.id });
    },
    [navigation],
  );

  const handleLongPress = useLongPressFileActions();

  const content = useMemo(() => {
    const { files, parent } = query.data;

    switch (parent.file_type) {
      case 'FOLDER':
        return files.length > 0 ? (
          <FileList
            files={files}
            hasTVPreferredFocus
            onLongPress={handleLongPress}
            onPress={handlePress}
          />
        ) : (
          <EmptyState
            icon="folder-open"
            message={translate('files_emptystate_shared_message')}
            title={translate('files_emptystate_title')}
          />
        );

      case 'VIDEO':
        return <VideoFile file={parent} />;

      default: {
        const error = new LocalizedError({
          message: 'Unsupported file type',
          recoverySuggestion: {
            description:
              'We currently only support video files in this app (for now).',
            trigger: {
              callback: () => navigation.goBack(),
              label: 'Go back',
            },
            type: 'action',
          },
          underlyingError: new Error('File type not supported'),
        });

        return <ErrorState error={error} />;
      }
    }
  }, [query.data, handlePress, handleLongPress, navigation]);

  return (
    <Screen
      actions={<FileActions file={query.data.parent} refetch={query.refetch} />}
      fullScreen={query.data.parent.file_type === 'VIDEO'}
      title={query.data.parent.name}
    >
      {content}
    </Screen>
  );
}

export default withSuspense(FileScreen, {
  localizeError: (e) => localizeError(e, createFetchFilesErrorLocalizers()),
});
