export const normalizeRouteName = (routeName: null | string) => {
  return routeName === null ? "default" : routeName;
};

export const normalizeRouteDisplayName = (routeName: null | string) => {
  const derivedRouteName = routeName === null ? "default" : routeName;

  return derivedRouteName === "default"
    ? "Amsterdam (Direct)"
    : derivedRouteName;
};
