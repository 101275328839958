import { useAPI } from "@putdotio/core";
import { FileURLProvider } from "@putdotio/utilities";
import { useMemo } from "react";

export const useFileURLProvider = () => {
  const api = useAPI();

  const fileURLProvider = useMemo(() => {
    if (!api.options.baseURL || !api.token) {
      throw new Error("API baseURL or token is not set");
    }

    return new FileURLProvider(api.options.baseURL, api.token);
  }, [api.options.baseURL, api.token]);

  return fileURLProvider;
};
