import type { NavigationContainerRef } from '@react-navigation/native';
import type { NavigationContainerProps } from '@react-navigation/native';

import { useTheme } from '@/lib/theme';
import { NavigationContainer as BaseNavigationContainer } from '@react-navigation/native';
import { useRef } from 'react';

export function NavigationContainer({
  children,
  ...props
}: NavigationContainerProps) {
  const theme = useTheme();
  const navigation = useRef<NavigationContainerRef<
    Record<string, unknown>
  > | null>(null);

  return (
    <BaseNavigationContainer
      {...props}
      linking={{
        prefixes: ['https://tv.put.io/', 'putio://'],
      }}
      ref={navigation}
      theme={{
        colors: {
          background: theme.colors.appBg,
          border: theme.colors.border,
          card: theme.colors.componentBg,
          notification: theme.colors.yellowSolid,
          primary: theme.colors.appBg,
          text: theme.colors.text,
        },
        dark: theme.config.colorMode === 'dark',
      }}
    >
      {children}
    </BaseNavigationContainer>
  );
}
