import type { PropsWithChildren } from 'react';

import { LoadingOverlay } from '@/components/overlay';
import { Label } from '@/components/ui/text';
import { styled } from '@/lib/theme';

const Overlay = styled.View(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.overlayFull,
  flex: 1,
  height: '100%',
  justifyContent: 'center',
  width: '100%',
}));

const Root = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.appBg,
  borderColor: theme.colors.line,
  borderRadius: theme.radii.default,
  borderWidth: 1,
  maxWidth: '50%',
  minWidth: '25%',
  paddingHorizontal: theme.spacing.md,
  paddingVertical: theme.spacing.md,
}));

const Title = styled(Label)((props) => ({
  color: props.theme.colors.textContrast,
  fontSize: props.theme.text.body.fontSize,
  paddingBottom: props.theme.spacing.sm,
}));

const Content = styled.View``;

type ModalProps = PropsWithChildren<{
  loading?: boolean;
  style?: Record<string, unknown>;
  title?: string;
}>;

export function Modal({ children, loading, style, title }: ModalProps) {
  return (
    <Overlay>
      {loading ? <LoadingOverlay /> : null}

      <Root style={style}>
        {title ? <Title numberOfLines={2}>{title}</Title> : null}
        <Content>{children}</Content>
      </Root>
    </Overlay>
  );
}
