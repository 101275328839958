import type { FetchQueryOptions, UseQueryOptions } from '@tanstack/react-query';

import { QueryClient, useQuery } from '@tanstack/react-query';

import { BFFError } from '../errors';
import { supabase } from '../supabase';

const fetchData = async <RemoteConfig extends Record<string, unknown>>(
  appID: number,
) => {
  const { data, error } = await supabase
    .from('remote_config_by_app')
    .select('config')
    .eq('app_id', appID);

  if (error) {
    throw new BFFError('Could not fetch remote config by app');
  }

  if (!data || data.length === 0) {
    throw new BFFError('Could not find remote config by app');
  }

  return data[0].config as RemoteConfig;
};

export const useRemoteConfig = <RemoteConfig extends Record<string, unknown>>(
  appID: number,
  options: UseQueryOptions<RemoteConfig> & {
    initialData: RemoteConfig;
  },
) => {
  const query = useQuery<RemoteConfig>({
    queryFn: () => fetchData<RemoteConfig>(appID),
    queryKey: ['bff', 'remote-config-by-app', appID],
    ...options,
  });

  return query;
};

export const usePrefetchRemoteConfig = () => {
  const queryClient = new QueryClient();

  return async <RemoteConfig extends Record<string, unknown>>(
    appID: number,
    options: FetchQueryOptions<RemoteConfig> = {},
  ) => {
    await queryClient.prefetchQuery<RemoteConfig>({
      queryFn: () => fetchData<RemoteConfig>(appID),
      queryKey: ['bff', 'remote-config-by-app', appID],
      ...options,
    });
  };
};
