import type {
  IHistoryEvent,
  IHistoryFileSharedEvent,
  IHistoryTransferCompletedEvent,
  IHistoryZipCreatedEvent,
} from "@putdotio/api-client";

export type WebHistoryEvent = Exclude<IHistoryEvent, IHistoryZipCreatedEvent>;

export type TVHistoryEvent =
  | IHistoryFileSharedEvent
  | IHistoryTransferCompletedEvent;

export const getFilteredEventsForTV = (events: IHistoryEvent[]) =>
  events.filter(
    (e): e is TVHistoryEvent =>
      e.type === "file_shared" || e.type === "transfer_completed",
  );

export const getFilteredEventsForWeb = (events: IHistoryEvent[]) =>
  events.filter((e): e is WebHistoryEvent => e.type !== "zip_created");
