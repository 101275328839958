import type { SelectModalOption } from '@/components/modal/select-modal';

import { SelectModal } from '@/components/modal/select-modal';
import { useConfigValue } from '@/features/config/lib';

import { useClearSearchHistory } from '../queries/search-history';

type Option = SelectModalOption<string> & { onPress: () => void };

export function SearchSettingsModal({ hide }: { hide: () => void }) {
  const [enabled, setEnabled] = useConfigValue('searchHistoryEnabled');
  const clear = useClearSearchHistory();

  const options = [
    {
      icon: 'history',
      label: enabled ? 'Disable search history' : 'Show search history',
      onPress: () => {
        if (enabled) {
          clear();
        }

        setEnabled(!enabled);
      },
      value: 'opt-in',
    },
    enabled
      ? {
          destructive: true,
          icon: 'delete',
          label: 'Clear search history',
          onPress: clear,
          value: 'clear',
        }
      : undefined,
  ].filter(Boolean) as Option[];

  return (
    <SelectModal
      onHide={hide}
      onSelect={(value: string) => {
        const option = options.find((option) => option.value === value);

        if (option) {
          option.onPress();
        }
      }}
      options={options}
      title="Search settings"
    />
  );
}
