import '@/lib/polyfills';
import '@/lib/why-did-you-render';

// eslint-disable-next-line perfectionist/sort-imports
import config from '@/config';
import { getAPIClient } from '@/lib/api';
import { ModalProvider } from '@/lib/contexts/modal';
import { PrefetchProvider } from '@/lib/contexts/prefetch';
import { QueryProvider } from '@/lib/contexts/query';
import { ToastProvider } from '@/lib/contexts/toast';
import { UpdateNotifierProvider } from '@/lib/contexts/update-notifier';
import { configureLogger } from '@/lib/logger';
import { NavigationRoot } from '@/lib/navigation/root';
import { Sentry } from '@/lib/sentry';
import { GlobalStyle, ThemeProvider, createTheme } from '@/lib/theme';
import { APIProvider } from '@putdotio/core';
import { AuthProvider } from '@putdotio/features';

configureLogger();
Sentry.init(config.sentry);

function App() {
  return (
    <ThemeProvider theme={createTheme({ colorMode: 'dark' })}>
      <GlobalStyle />

      <APIProvider getAPIClient={getAPIClient}>
        <QueryProvider>
          <AuthProvider>
            <ModalProvider>
              <PrefetchProvider>
                <UpdateNotifierProvider>
                  <ToastProvider>
                    <NavigationRoot />
                  </ToastProvider>
                </UpdateNotifierProvider>
              </PrefetchProvider>
            </ModalProvider>
          </AuthProvider>
        </QueryProvider>
      </APIProvider>
    </ThemeProvider>
  );
}

export default App;
