import { Stack } from "@/components/ui/stack";
import { Text } from "@/components/ui/text";
import config from "@/config";
import { styled } from "@/lib/theme";
import { getAppInfo } from "@/lib/utils";
import { useMemo } from "react";

import { Modal } from "./modal";

const AppLink = styled(Text)`
  color: ${({ theme }) => theme.colors["yellow-solid"]};
`;

export function UpdateNotifierModal() {
  const app = getAppInfo();

  const platformSpecificContent = useMemo(() => {
    switch (app.id) {
      case config.oauthApps.fireTV:
        return (
          <Text>
            Please go to <AppLink>put.io/firetv</AppLink> and follow the
            instructions to install the new version.
          </Text>
        );

      case config.oauthApps.tvOS:
        return (
          <Text>Please open the AppStore and install the latest update.</Text>
        );

      default:
        return <Text>Please open your app store and check for updates.</Text>;
    }
  }, [app.id]);

  return (
    <Modal title="Heads up!">
      <Stack align="flex-start" direction="column">
        <Text>There is a new version of the app available.</Text>
        {platformSpecificContent}
      </Stack>
    </Modal>
  );
}
