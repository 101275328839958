import type { PropsWithChildren } from "react";

import { SplashScreen } from "@/components";
import { usePrefetchAuthToken } from "@/features/auth/lib";
import { usePrefetchRemoteConfig } from "@/lib/queries/remote-config";
import { useCallback, useEffect, useState } from "react";

export function PrefetchProvider({ children }: PropsWithChildren) {
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const [prefetching, setPrefetching] = useState(true);
  const prefetchAuthToken = usePrefetchAuthToken();
  const prefetchRemoteConfig = usePrefetchRemoteConfig();

  const prefetchAll = useCallback(
    () => Promise.all([prefetchAuthToken(), prefetchRemoteConfig()]),
    [prefetchAuthToken, prefetchRemoteConfig],
  );

  useEffect(() => {
    prefetchAll()
      .then(() => setPrefetching(false))
      .catch(() => setPrefetching(false));
  }, [prefetchAll]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplashScreen(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (prefetching || showSplashScreen) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}
