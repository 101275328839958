import type { PropsWithChildren, ReactNode } from 'react';

import { Label } from '@/components/ui/text';
import { localizeError } from '@/lib/errors/localize-error';
import { styled } from '@/lib/theme';
import { LocalizedError } from '@putdotio/utilities';

import { ErrorState } from './error-state';
import { XStack } from './ui/stack';

type ScreenProps = PropsWithChildren<{
  actions?: ReactNode;
  fullScreen?: boolean;
  header?: ReactNode;
  title?: string;
}>;

const HeaderRoot = styled.View(({ theme }) => ({
  alignItems: 'flex-start',
  borderBottomColor: theme.colors.line,
  borderBottomWidth: 1,
  paddingBottom: theme.screen.header.paddingBottom,
  paddingLeft: theme.screen.header.paddingLeft,
  paddingRight: theme.screen.header.paddingRight,
  paddingTop: theme.screen.header.paddingTop,
}));

const HeaderTitleContainer = styled.View(() => ({
  flex: 1,
}));

const Body = styled.View(({ theme }) => ({
  flex: 1,
  marginBottom: theme.screen.marginBottom,
  marginTop: theme.screen.marginTop,
}));

export function Screen({
  actions,
  children,
  fullScreen = false,
  header,
  title,
}: ScreenProps) {
  if (fullScreen) {
    return <>{children}</>;
  }

  if (!title && !header) {
    return <Body>{children}</Body>;
  }

  return (
    <>
      {header ? (
        <HeaderRoot>{header}</HeaderRoot>
      ) : (
        <HeaderRoot>
          {actions ? (
            <XStack spacing="md" width="100%">
              <HeaderTitleContainer>
                <Label numberOfLines={1}>{title}</Label>
              </HeaderTitleContainer>

              {actions}
            </XStack>
          ) : (
            <Label numberOfLines={1}>{title}</Label>
          )}
        </HeaderRoot>
      )}

      <Body>{children}</Body>
    </>
  );
}

export function ErrorScreen({
  error,
  ...screenProps
}: ScreenProps & { error: unknown }) {
  const localizedError =
    error instanceof LocalizedError ? error : localizeError(error);

  return (
    <Screen {...screenProps}>
      <ErrorState error={localizedError} />
    </Screen>
  );
}
