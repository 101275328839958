import type { PreVideoPromptProps } from '@/components/pre-video-prompt';
import type { ConversionStatusData } from '@putdotio/features';

import { Label, Text, YStack } from '@/components';
import { PreVideoPrompt } from '@/components/pre-video-prompt';
import { styled } from '@/lib/theme';
import { translate } from '@putdotio/translations';
import { useMemo } from 'react';

type VideoConversionStatusProps = Pick<
  PreVideoPromptProps,
  'posterURL' | 'title'
> & {
  data: ConversionStatusData;
};

const ConvertStatusLabel = styled(Label)(({ theme }) => ({
  color: theme.colors.textContrast,
  fontSize: theme.text.caption.fontSize,
}));

export function VideoConversionStatus({
  data,
  posterURL,
  title,
}: VideoConversionStatusProps) {
  const content = useMemo(() => {
    switch (data.status) {
      case 'IN_QUEUE':
        return <Text>{translate('files_convert_inqueue_label')}</Text>;

      case 'CONVERTING':
        return <Text>{`${data.percent_done || 0}%`}</Text>;

      case 'COMPLETED':
        return <Text>{translate('files_convert_done_label')}</Text>;

      case 'ERROR':
        return <Text>{translate('files_convert_error_label')}</Text>;

      case 'NEEDS_CONVERSION':
        return <Text>{translate('files_convert_waiting_label')}</Text>;

      default:
        return <Text>{data.status}</Text>;
    }
  }, [data]);

  return (
    <PreVideoPrompt posterURL={posterURL} title={title}>
      <YStack align="center" spacing="lg">
        <Text style={{ textAlign: 'center' }}>
          {translate('files_convert_message')}
        </Text>

        <YStack align="center" spacing="sm">
          <ConvertStatusLabel>
            {translate('files_convert_status_label')}
          </ConvertStatusLabel>

          {content}
        </YStack>
      </YStack>
    </PreVideoPrompt>
  );
}
