import Jed from "jed";

import developmentEnglish from "./languages/developmentEnglish.json";

export type Translations = typeof developmentEnglish;
export type TranslationKey = keyof Translations;
export type Language = "en";

const loadTranslations = (language: Language) => {
  return developmentEnglish;
};

export const createTranslate = (language: Language) => {
  const i18n = new Jed({});
  const translations = loadTranslations(language);

  return (key: TranslationKey, ...args: Array<number | string>) => {
    const translation = translations[key];

    if (Array.isArray(translation)) {
      const [singular, plural] = translation;
      const [number1, number2, ...rest] = args;
      const normalizedNumber1 = typeof number1 === "number" ? number1 : 0;
      const normalizedNumber2 = typeof number2 === "number" ? number2 : 0;

      return i18n
        .translate(singular, plural)
        .ifPlural(normalizedNumber1, plural)
        .fetch([normalizedNumber2, ...rest]) as string;
    }

    return i18n.translate(translation).fetch(args) as string;
  };
};

export const translate = createTranslate("en");
