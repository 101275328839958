import type { ListItemProps } from '@/components';
import type { VideoPlayerProps } from '@/components/video-player';
import type { SectionListData } from 'react-native';

import {
  ListItem,
  ListSectionFooter,
  ListSectionHeader,
  Screen,
} from '@/components';
import { withSuspense } from '@/lib/hocs/with-suspense';
import { useNavigation } from '@/lib/navigation';
import { useTestStreams } from '@putdotio/features';
import { SectionList } from 'react-native';

function DiagnosticsScreen() {
  const navigation = useNavigation();
  const { data } = useTestStreams({ suspense: true });

  if (!data) {
    return null;
  }

  const sections: SectionListData<ListItemProps>[] = [
    {
      data: data.map((stream, index) => {
        const source = stream.source as VideoPlayerProps['source'];

        return {
          description: `${source.video.uri}`,
          hasTVPreferredFocus: index === 0,
          icon: {
            family: 'lucide',
            name: source.playbackType === 'hls' ? 'file-video-2' : 'file-video',
          },
          onPress: () => {
            navigation.navigate('diagnostics/video-player', { stream });
          },
          title: stream.title,
        };
      }),
      title: 'Test streams',
    },
  ];

  return (
    <Screen title="Diagnostics">
      <SectionList
        persistentScrollbar
        renderItem={({ item }) => <ListItem {...item} />}
        renderSectionFooter={() => <ListSectionFooter />}
        renderSectionHeader={({ section }) => (
          <ListSectionHeader title={section.title} />
        )}
        sections={sections}
      />
    </Screen>
  );
}

export default withSuspense(DiagnosticsScreen);
