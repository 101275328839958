import { useAPI } from '@putdotio/core';
import { ExtendableError } from '@putdotio/utilities';
import { useQuery } from '@tanstack/react-query';

class NotVerifiedError extends ExtendableError {}

export const useVerifyAuthCode = (code = '') => {
  const api = useAPI();
  const queryKey = ['verify-auth-code', code];

  const result = useQuery<string>({
    enabled: code !== '',
    queryFn: async () => {
      const { data } = await api.Auth.CheckCodeMatch(code);

      if (data.oauth_token) {
        return data.oauth_token;
      }

      throw new NotVerifiedError();
    },
    queryKey,
    retry(_failureCount, error) {
      return error instanceof NotVerifiedError;
    },
    retryDelay: 3000,
  });

  return result;
};
